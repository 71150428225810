import { useStore } from 'react-redux';

import { CHUNK_SIZE } from './constants';
import { LargeUpload, LargeUploadWithS3, Upload, UploaderStore, UploadStatus } from './types';

export const useUploaderStore = () => {
  return useStore<UploaderStore>();
};

export const isFinishedUploading = (uploads: Upload[]) =>
  !uploads.some(
    (u) =>
      u.status === UploadStatus.uploading || u.status === UploadStatus.queued || u.status === UploadStatus.preparing,
  );

export const isLarge = (upload: Upload): upload is LargeUpload => upload.file.size >= CHUNK_SIZE;

export const uploadHasUploadInfo = (upload: Upload): upload is LargeUploadWithS3 => !!upload.s3Info.uploadUrlInfo;
