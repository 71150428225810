import { BaseCustomField, CustomFieldListResponse, CustomFieldType, CustomFieldTypeName } from '@air/api/types';

export const selectableCustomFields = [CustomFieldTypeName.singleSelect, CustomFieldTypeName.multiSelect];

export const getIsFieldTypeSelectable = ({
  fieldTypeId,
  fieldTypes,
}: {
  fieldTypeId: string;
  fieldTypes: CustomFieldType[] | undefined;
}) => {
  if (!fieldTypes) return false;
  return fieldTypes.some((ft) => ft.id === fieldTypeId && selectableCustomFields.includes(ft.title));
};

export const getIsCustomFieldSelectable = ({ customField }: { customField: BaseCustomField | undefined }) => {
  if (!customField) return false;
  return selectableCustomFields.includes(customField.type.title);
};

export const getSelectCustomFields = (customFields: BaseCustomField[]) =>
  customFields?.filter((customField) => getIsCustomFieldSelectable({ customField }));

export const editCustomFieldsInCache = ({
  existingCustomFields,
  newCustomField,
}: {
  existingCustomFields?: CustomFieldListResponse;
  newCustomField: Pick<BaseCustomField, 'description' | 'id' | 'name'>;
}) => {
  if (!existingCustomFields) return;

  return {
    ...existingCustomFields,
    data: existingCustomFields.data?.map((customField) => {
      if (customField.id === newCustomField.id) {
        return {
          ...customField,
          name: newCustomField.name,
          description: newCustomField.description,
        };
      }

      return customField;
    }),
  };
};
