import 'react-datepicker/dist/react-datepicker.css';
import '../styles/date-input.css';

import { forwardRef, MouseEvent, useCallback } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { DateInputField, DateInputFieldProps } from './DateInputField';
import { DateInputHeader } from './DateInputHeader';

export interface DateInputProps
  extends Omit<ReactDatePickerProps, 'onChange' | 'selectsRange'>,
    Pick<DateInputFieldProps, 'name' | 'placeholder' | 'style' | 'className' | 'size'> {
  name?: string | undefined;
  onChange: (date?: Date) => void;
  showClearButton?: boolean;
  showCalendarIcon?: boolean;
  testId?: string;
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  (
    {
      showCalendarIcon = true,
      onChange,
      name,
      size,
      showClearButton = true,
      style,
      className,
      placeholder,
      ...props
    }: DateInputProps,
    ref,
  ) => {
    const onDateChange: ReactDatePickerProps['onChange'] = useCallback(
      (date) => {
        onChange(date as Date);
      },
      [onChange],
    );

    const onClear = useCallback(
      (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDateChange(null, e);
      },
      [onDateChange],
    );

    const renderCustomHeader = useCallback<Required<ReactDatePickerProps>['renderCustomHeader']>(
      (params) => <DateInputHeader {...params} />,
      [],
    );

    const { testId, ...restProps } = props;

    return (
      <ReactDatePicker
        showPopperArrow={false}
        onChange={onDateChange}
        customInput={
          <DateInputField
            showCalendarIcon={showCalendarIcon}
            name={name}
            ref={ref}
            size={size}
            className={className}
            style={style}
            placeholder={placeholder}
            onClear={showClearButton ? onClear : undefined}
            data-testid={testId}
          />
        }
        renderCustomHeader={renderCustomHeader}
        {...restProps}
      />
    );
  },
);

DateInput.displayName = 'DateInput';
