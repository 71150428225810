import * as ReachAlertDialog from '@reach/alert-dialog';
import classNames from 'classnames';
import { ComponentProps } from 'react';

export type AlertModalDescriptionProps = ReachAlertDialog.AlertDialogLabelProps & Omit<ComponentProps<'div'>, 'ref'>;

export const AlertModalDescription = ({ className, ...restOfProps }: AlertModalDescriptionProps) => {
  return (
    <ReachAlertDialog.AlertDialogDescription
      className={classNames('text-16 text-grey-11', className)}
      {...restOfProps}
    />
  );
};
