import { TextareaPrimitive, type TXProp } from '@air/zephyr';
import { useField, useFormikContext } from 'formik';
import { forwardRef, KeyboardEvent } from 'react';

import { EditableTextFormValues } from './EditableText';

export type EditableTextTextareaProps = {
  isSingleLine?: boolean;
  id: string;
  label: string;
  maxLength?: number;
  name: string;
  onValueChange?: (value: string) => void;
  required?: boolean;
  tx?: TXProp;
};

export const EditableTextTextarea = forwardRef<HTMLTextAreaElement, EditableTextTextareaProps>(
  (
    { id, isSingleLine, label, maxLength, name, onValueChange, required, tx }: EditableTextTextareaProps,
    forwardedRef,
  ) => {
    const { handleReset, submitForm } = useFormikContext<EditableTextFormValues>();
    const [field] = useField(name);

    return (
      <TextareaPrimitive
        aria-label={label}
        id={id}
        maxLength={maxLength}
        onKeyPress={async (event: KeyboardEvent<HTMLTextAreaElement>) => {
          if (event.key === 'Enter' && isSingleLine) {
            event.stopPropagation();
            event.preventDefault();
          }

          if (event.key === 'Enter' && !event.shiftKey) {
            event.stopPropagation();
            event.preventDefault();
            await submitForm();
          }
        }}
        onKeyUp={(event: KeyboardEvent<HTMLTextAreaElement>) => {
          if (event.key === 'Escape') {
            event.stopPropagation();
            handleReset();
          }
        }}
        ref={forwardedRef}
        required={required}
        tx={{
          position: 'absolute',
          inset: 0,
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          maxHeight: '100%',
          overflow: 'hidden',
          color: 'inherit',
          fontFamily: 'inherit',
          fontFeatureSettings: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
          letterSpacing: 'inherit',
          lineHeight: 'inherit',
          ...tx,
        }}
        variant="unstyled"
        {...field}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          /**
           * If `isSingleLine` is true, we will replace all new lines with a space.
           * NOTE: When this the pasted text is formatted, it will prevent the user
           * from being able to undo with `Ctrl + Z` or `Cmd + Z`.
           */
          if (isSingleLine) {
            event.target.value = event.target.value.replace(/(\r\n|\n|\r)/gm, ' ');
          }

          onValueChange?.(event.target.value);

          field.onChange(event);
        }}
        onBlur={submitForm}
        data-testid="EDITABLE_TEXT_TEXTAREA"
      />
    );
  },
);

EditableTextTextarea.displayName = 'EditableTextTextarea';
