import { type ResponseCustomFieldValue } from '@air/api/types';
import { Chip, ChipProps } from '@air/primitive-chip';
import { kebabCase } from 'lodash';
import { forwardRef } from 'react';

import Linkify from '~/components/Linkify';

export type CustomFieldProps = ChipProps & {
  field: Omit<ResponseCustomFieldValue, 'color'> & {
    color: Partial<Omit<ResponseCustomFieldValue['color'], 'name'>> &
      Required<Pick<ResponseCustomFieldValue['color'], 'name'>>;
  };
};

export const CustomField = forwardRef<HTMLDivElement, CustomFieldProps>(({ field, ...restOfProps }, forwardedRef) => {
  return (
    <Chip
      appearance="rectangular"
      color={kebabCase(field.color.name) as ChipProps['color']}
      data-testid="CUSTOM_FIELD"
      data-id={field.id}
      data-color={field.color.name}
      data-value={field.value}
      size="medium"
      {...restOfProps}
      ref={forwardedRef}
    >
      <span className="truncate">
        <Linkify>{field.value}</Linkify>
      </span>
    </Chip>
  );
});

CustomField.displayName = 'CustomField';
