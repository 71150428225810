import { removeUploadsAction, setUploadAbortedAction, Upload } from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { abortUpload } from '../utilities';

export type UseDeleteUploadParams = {
  onDelete?: (upload: Upload) => void;
};

export const useDeleteUpload = ({ onDelete }: UseDeleteUploadParams | undefined = {}) => {
  const dispatch = useDispatch();

  const deleteUpload = useCallback(
    async (upload: Upload) => {
      try {
        const { clipId } = upload;

        abortUpload(upload);
        // upload has actually started if clipId is not empty
        if (clipId) {
          dispatch(setUploadAbortedAction({ uploadId: upload.id }));

          onDelete?.(upload);
        }
      } finally {
        dispatch(removeUploadsAction({ uploadIds: [upload.id] }));
      }
    },
    [dispatch, onDelete],
  );

  return {
    deleteUpload,
  };
};
