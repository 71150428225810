import {
  Audio as AudioIcon,
  CompressedFiles as CompressedFilesIcon,
  Database as DatabaseIcon,
  Document as DocumentIcon,
  FolderOpen as FolderOpenIcon,
  Image as ImageIcon,
  Pdf as PdfIcon,
  Presentations as PresentationsIcon,
  ThreeD as ThreeDIcon,
  Vector as VectorIcon,
  Video as VideoIcon,
} from '@air/next-icons';

import { type IconType } from '~/types/icons';

export interface FileGroupType {
  iconName: string;
  iconComponent: IconType;
  iconColor: string;
  bgColor: string;
  modalIconColor: string;
}

const audioFile: FileGroupType = {
  iconName: 'Audio',
  iconComponent: AudioIcon,
  iconColor: '#010256',
  bgColor: '#EDE6FF',
  modalIconColor: '#AC78FF',
};

const compressedFile: FileGroupType = {
  iconName: 'Compressed files',
  iconComponent: CompressedFilesIcon,
  iconColor: '#4C2103',
  bgColor: '#FDD778',
  modalIconColor: '#CE4D13',
};

const databaseFile: FileGroupType = {
  iconName: 'Database',
  iconComponent: DatabaseIcon,
  iconColor: '#033B51',
  bgColor: '#AAF255',
  modalIconColor: '#61D800',
};

export const genericFile: FileGroupType = {
  iconName: 'Folder open',
  iconComponent: FolderOpenIcon,
  iconColor: '#4D4D4D',
  bgColor: '#DCDCDC',
  modalIconColor: '#666666',
};

const imageFile: FileGroupType = {
  iconName: 'Image',
  iconComponent: ImageIcon,
  iconColor: '#07B3BE',
  bgColor: '#E6F4F6',
  modalIconColor: '#05889F',
};

const pdfFile: FileGroupType = {
  iconName: 'PDF',
  iconComponent: PdfIcon,
  iconColor: '#ED3B3B',
  bgColor: '#FCF0F0',
  modalIconColor: '#ED3B3B',
};

const presentationFile: FileGroupType = {
  iconName: 'Presentations',
  iconComponent: PresentationsIcon,
  iconColor: '#4C2103',
  bgColor: '#FFAA3B',
  modalIconColor: '#FF6D00',
};

const textFile: FileGroupType = {
  iconName: 'Document',
  iconComponent: DocumentIcon,
  iconColor: '#0204A4',
  bgColor: '#91AEFF',
  modalIconColor: '#3820EA',
};

const threeDFile: FileGroupType = {
  iconName: '3D',
  iconComponent: ThreeDIcon,
  iconColor: '#280DBC',
  bgColor: '#B4CFFF',
  modalIconColor: '#280DBC',
};

const vectorFile: FileGroupType = {
  iconName: 'Vector',
  iconComponent: VectorIcon,
  iconColor: '#05889F',
  bgColor: '#70E7E7',
  modalIconColor: '#033B51',
};

const videoFile: FileGroupType = {
  iconName: 'Video',
  iconComponent: VideoIcon,
  iconColor: '#841328',
  bgColor: '#ff8383',
  modalIconColor: '#a1222f',
};

export type FileExtensionName =
  | '3ds'
  | '3fr'
  | 'ai'
  | 'ari'
  | 'aep'
  | 'aiff'
  | 'ark'
  | 'arw'
  | 'avi'
  | 'bay'
  | 'bitmap'
  | 'blend'
  | 'bmp'
  | 'bz'
  | 'c4d'
  | 'cap'
  | 'collada'
  | 'cr2'
  | 'crw'
  | 'csv'
  | 'dat'
  | 'data'
  | 'db'
  | 'dcr'
  | 'dcs'
  | 'dng'
  | 'drf'
  | 'dbf'
  | 'doc'
  | 'docx'
  | 'dwg'
  | 'eip'
  | 'erf'
  | 'eps'
  | 'exif'
  | 'faw'
  | 'fbx'
  | 'fcp'
  | 'fff'
  | 'fla'
  | 'flac'
  | 'flv'
  | 'gif'
  | 'gpr'
  | 'hdri'
  | 'html'
  | 'iges'
  | 'iiq'
  | 'indb'
  | 'indd'
  | 'jpg'
  | 'jpeg'
  | 'k25'
  | 'kdc'
  | 'key'
  | 'log'
  | 'm4a'
  | 'ma'
  | 'mb'
  | 'mdb'
  | 'mdc'
  | 'mef'
  | 'mel'
  | 'midi'
  | 'mos'
  | 'MOV'
  | 'mov'
  | 'mrw'
  | 'mp3'
  | 'mp4'
  | 'mpg'
  | 'nef'
  | 'nrw'
  | 'numbers'
  | 'obm'
  | 'odp'
  | 'ods'
  | 'odt'
  | 'obj'
  | 'orf'
  | 'pages'
  | 'pef'
  | 'pdf'
  | 'png'
  | 'ppt'
  | 'pptx'
  | 'prproj'
  | 'psb'
  | 'psd'
  | 'ptx'
  | 'pxn'
  | 'raf'
  | 'rar'
  | 'raw'
  | 'rtf'
  | 'rw2'
  | 'rwl'
  | 'rwz'
  | 'sav'
  | 'sr2'
  | 'srf'
  | 'srw'
  | 'sql'
  | 'step'
  | 'stl'
  | 'svg'
  | 'tar'
  | 'tif'
  | 'tiff'
  | 'txt'
  | 'vrml'
  | 'wav'
  | 'webp'
  | 'wma'
  | 'wmv'
  | 'x3d'
  | 'x3f'
  | 'xls'
  | 'xlsx'
  | 'xml'
  | 'zip';

export const fileExtensionStylingMap: { [key in FileExtensionName]: FileGroupType } = {
  '3ds': threeDFile,
  '3fr': imageFile,
  aep: genericFile,
  ai: vectorFile,
  aiff: audioFile,
  ari: imageFile,
  arw: imageFile,
  ark: compressedFile,
  avi: videoFile,
  bay: imageFile,
  bitmap: imageFile,
  blend: threeDFile,
  bmp: imageFile,
  bz: compressedFile,
  cap: imageFile,
  c4d: threeDFile,
  collada: threeDFile,
  cr2: imageFile,
  crw: imageFile,
  csv: databaseFile,
  dat: databaseFile,
  data: imageFile,
  dcr: imageFile,
  dcs: imageFile,
  dng: imageFile,
  drf: imageFile,
  db: databaseFile,
  dbf: databaseFile,
  doc: textFile,
  docx: textFile,
  dwg: threeDFile,
  eip: imageFile,
  erf: imageFile,
  eps: vectorFile,
  exif: imageFile,
  faw: imageFile,
  fbx: threeDFile,
  fcp: genericFile,
  fff: imageFile,
  fla: genericFile,
  flac: audioFile,
  flv: videoFile,
  gif: imageFile,
  gpr: imageFile,
  hdri: imageFile,
  html: textFile,
  iges: threeDFile,
  iiq: imageFile,
  indb: genericFile,
  indd: genericFile,
  jpg: imageFile,
  jpeg: imageFile,
  k25: imageFile,
  kdc: imageFile,
  key: presentationFile,
  log: textFile,
  ma: threeDFile,
  m4a: audioFile,
  mb: threeDFile,
  mdb: databaseFile,
  mdc: imageFile,
  mef: imageFile,
  mel: threeDFile,
  midi: audioFile,
  mos: imageFile,
  MOV: videoFile,
  mov: videoFile,
  mrw: imageFile,
  mp3: audioFile,
  mp4: videoFile,
  mpg: videoFile,
  nef: imageFile,
  nrw: imageFile,
  numbers: databaseFile,
  obm: imageFile,
  odp: presentationFile,
  ods: databaseFile,
  odt: textFile,
  obj: threeDFile,
  orf: imageFile,
  pages: textFile,
  pdf: pdfFile,
  pef: imageFile,
  png: imageFile,
  ppt: presentationFile,
  pptx: presentationFile,
  prproj: genericFile,
  psb: imageFile,
  psd: imageFile,
  ptx: imageFile,
  pxn: imageFile,
  raf: imageFile,
  rar: compressedFile,
  raw: imageFile,
  rtf: textFile,
  rw2: imageFile,
  rwl: imageFile,
  rwz: imageFile,
  sav: databaseFile,
  sql: databaseFile,
  step: threeDFile,
  stl: threeDFile,
  sr2: imageFile,
  srf: imageFile,
  srw: imageFile,
  svg: vectorFile,
  tar: databaseFile,
  tif: imageFile,
  tiff: imageFile,
  txt: textFile,
  vrml: threeDFile,
  wav: audioFile,
  webp: imageFile,
  wma: audioFile,
  wmv: videoFile,
  x3d: threeDFile,
  x3f: imageFile,
  xls: databaseFile,
  xlsx: databaseFile,
  xml: databaseFile,
  zip: compressedFile,
};
