import { ActionSheetContent, ActionSheetContentProps } from './ActionSheetContent';
import { ActionSheetOverlay } from './ActionSheetOverlay';

export type ActionSheetProps = Omit<ActionSheetContentProps, 'title'> & {
  onClose: () => void;
};

export const ActionSheet = ({ onClose, ...restOfProps }: ActionSheetProps) => {
  return (
    <ActionSheetOverlay onClose={onClose}>
      <ActionSheetContent onClose={onClose} {...restOfProps} />
    </ActionSheetOverlay>
  );
};
