import { ClipSource, ClipStatus, ClipType } from '@air/api/types';
import { createReducer } from '@reduxjs/toolkit';
import { isNull, merge } from 'lodash';

import {
  addAssetsToBoardsSuccessAction,
  clearCentralizedClipAction,
  favoriteClipsFailureAction,
  favoriteClipsRequestAction,
  moveAssetsToBoardRequestAction,
  removeBoardFromCentralizedClipAction,
  setCentralizedClipAction,
  setCentralizedClipPDFNumberOfPagesAction,
  setCentralizedIsClipLoadingAction,
  unfavoriteClipsFailureAction,
  unfavoriteClipsRequestAction,
  updateAssetDescriptionSuccessAction,
  updateAssetTagsSuccessAction,
  updateAssetTitleSuccessAction,
  updateCentralizedClipAction,
  updateCentralizedClipAssetsAction,
  updateCentralizedClipBoardThumbnails,
  updateRecordedAtDateSuccessAction,
} from '~/store/centralizedClip/actions';

import { CentralizedClipState } from './types';

export const initialState: CentralizedClipState = {
  loading: false,
  clip: {
    altResolutions: [],
    assetId: '',
    isDefault: false,
    version: 1,
    id: '',
    isDemo: false,
    accountId: '',
    workspaceId: '',
    source: ClipSource.web,
    ext: '',
    type: ClipType.photo,
    size: 0,
    status: ClipStatus.created,
    bookmarked: false,
    createdAt: new Date().toISOString(),
    recordedAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    title: '',
    description: '',
    importedName: '',
    height: 0,
    width: 0,
    rotation: 0,
    ownerName: '',
    owner: {
      ownerName: '',
      ownerAvatar: '',
    },
    avatar: null,
    assets: {
      image: '',
    },
    tags: [],
    smartTags: [],
    boards: [],
    workspaceImage: '',
    workspaceName: '',
  },
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setCentralizedClipAction, (state, { payload: { clip } }) => {
      // @ts-ignore
      state.clip = {
        ...initialState.clip,
        ...clip,
        /**
         * This prevents a crash because withBoards: true sometimes returns null for boards anyway
         * @see https://air-labs-team.slack.com/archives/CCS4C8ALT/p1676057514625069
         */
        boards: clip.boards || [],
      };
    })
    .addCase(updateCentralizedClipAction, (state, { payload: { clip } }) => {
      const clipToMerge = state.clip;
      if (clip.tags) {
        clipToMerge.tags = clip.tags;
      }

      /**
       * This prevents a crash because withBoards: true sometimes returns null for boards anyway
       * @see https://air-labs-team.slack.com/archives/CCS4C8ALT/p1676057514625069
       */
      if (isNull(clip.boards)) {
        clip.boards = [];
      }

      state.clip = merge(state.clip, clip);
    })
    .addCase(updateAssetTitleSuccessAction, (state, action) => {
      const { clipId, title } = action.payload;
      if (state.clip.id === clipId) {
        state.clip.title = title;
      }
    })
    .addCase(updateCentralizedClipAssetsAction, (state, action) => {
      state.clip.assets = {
        ...state.clip.assets,
        ...action.payload.assets,
      };
    })
    .addCase(updateAssetDescriptionSuccessAction, (state, action) => {
      const { clipId, description } = action.payload;
      if (state.clip.id === clipId) {
        state.clip.description = description;
      }
    })
    .addCase(updateRecordedAtDateSuccessAction, (state, action) => {
      const { clipId, recordedAt } = action.payload;
      if (state.clip.id === clipId) {
        state.clip.recordedAt = recordedAt;
      }
    })
    .addCase(setCentralizedIsClipLoadingAction, (state, action) => {
      state.loading = action.payload.isLoading;
    })
    .addCase(favoriteClipsRequestAction, (state, action) => {
      const clipIds = action.payload.clipIds;
      if (state.clip.id && state.clip.id === clipIds[0]) {
        state.clip.bookmarked = true;
      }
    })
    .addCase(favoriteClipsFailureAction, (state, action) => {
      const clipIds = action.payload.clipIds;
      if (state.clip.id && state.clip.id === clipIds[0]) {
        state.clip.bookmarked = false;
      }
    })
    .addCase(unfavoriteClipsRequestAction, (state, action) => {
      const clipIds = action.payload.clipIds;
      if (state.clip.id && state.clip.id === clipIds[0]) {
        state.clip.bookmarked = false;
      }
    })
    .addCase(unfavoriteClipsFailureAction, (state, action) => {
      const clipIds = action.payload.clipIds;
      if (state.clip.id && state.clip.id === clipIds[0]) {
        state.clip.bookmarked = true;
      }
    })
    .addCase(addAssetsToBoardsSuccessAction, (state, action) => {
      if (action.payload.clipIds.includes(state.clip.id)) {
        state.clip.boards = [...state.clip.boards, ...action.payload.boards];
      }
    })
    .addCase(moveAssetsToBoardRequestAction, (state, action) => {
      if (action.payload.assetIds.includes(state.clip.assetId)) {
        state.clip.boards.push(action.payload.board);
      }
    })

    .addCase(removeBoardFromCentralizedClipAction, (state, action) => {
      const newBoards = state.clip.boards.filter((board) => board.id !== action.payload.boardId);

      state.clip.boards = newBoards;
    })

    .addCase(updateCentralizedClipBoardThumbnails, (state, action) => {
      const { newThumbnail, boardId } = action.payload;

      const boardIndex = state.clip.boards.findIndex((board) => board.id === boardId);

      if (!!state.clip.boards[boardIndex]) {
        state.clip.boards[boardIndex].thumbnails = [newThumbnail];
      }
    })

    .addCase(updateAssetTagsSuccessAction, (state, action) => {
      const asset = action.payload.clips.find(({ id }) => id === state.clip.id);
      if (asset) {
        state.clip.tags = asset.tags;
      }
    })

    .addCase(clearCentralizedClipAction, (state) => {
      state.clip = initialState.clip;
    })

    .addCase(setCentralizedClipPDFNumberOfPagesAction, (state, action) => {
      state.clip.pdfPageNumber = action.payload.pdfPageNumber;
    });
});
