import { Asset, Clip } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

import { CentralizedClip, CentralizedClipBoard } from './types';

export const setCentralizedClipAction = createAction<{
  clip: Partial<CentralizedClip> & Pick<CentralizedClip, 'id'>;
}>('SET_CENTRALIZED_CLIP');

export const setCentralizedIsClipLoadingAction = createAction<{ isLoading: boolean }>(
  'SET_CENTRALIZED_CLIP_IS_LOADING',
);

export const updateCentralizedClipAction = createAction(
  'UPDATE_CENTRALIZED_CLIP',
  (payload: { clip: Pick<CentralizedClip, 'id'> & Partial<Omit<CentralizedClip, 'id'>> }) => ({
    payload,
  }),
);

export const updateCentralizedClipBoardThumbnails = createAction(
  'UPDATE_CENTRALIZED_CLIP_BOARD_THUMBNAILS',
  (payload: { boardId: CentralizedClipBoard['id']; newThumbnail: string }) => ({
    payload,
  }),
);

export const removeBoardFromCentralizedClipAction = createAction(
  'REMOVE_BOARD_FROM_CENTRALIZED_CLIP',
  (payload: { boardId: CentralizedClipBoard['id'] }) => ({
    payload,
  }),
);

export const clearCentralizedClipAction = createAction('CLEAR_CENTRALIZED_CLIP');

export const updateAssetTagsSuccessAction = createAction<{ clips: Clip[] }>('UPDATE_TAGS_FROM_ASSETS_SUCCESS');

export interface AddAssetsToBoardsSuccessActionPayload {
  boards: CentralizedClipBoard[];
  clipIds: Clip['id'][];
}

export const addAssetsToBoardsSuccessAction =
  createAction<AddAssetsToBoardsSuccessActionPayload>('ADD_ASSETS_TO_BOARDS_SUCCESS');

type UpdateAssetTitleSuccessActionPayload = { clipId: Clip['id']; title: Clip['title'] };
export const updateAssetTitleSuccessAction =
  createAction<UpdateAssetTitleSuccessActionPayload>('UPDATE_ASSET_TITLE_SUCCESS');

type UpdateAssetDescriptionSuccessActionPayload = { clipId: Clip['id']; description: Clip['description'] };
export const updateAssetDescriptionSuccessAction = createAction<UpdateAssetDescriptionSuccessActionPayload>(
  'UPDATE_ASSET_DESCRIPTION_SUCCESS',
);

type UpdateRecordedAtDateSuccessActionPayload = { clipId: Clip['id']; recordedAt: Clip['recordedAt'] };
export const updateRecordedAtDateSuccessAction = createAction<UpdateRecordedAtDateSuccessActionPayload>(
  'UPDATE_ASSET_RECORDED_AT_DATE_SUCCESS',
);

export const deletedClipsSuccessAction = createAction<{ assetIds: Asset['id'][] }>('DELETE_CLIPS_SUCCESS');

export const favoriteClipsRequestAction = createAction<{ clipIds: Clip['id'][] }>('FAVORITE_CLIPS_REQUEST');
export const favoriteClipsFailureAction = createAction<{ clipIds: Clip['id'][] }>('FAVORITE_CLIPS_FAILURE');
export const unfavoriteClipsRequestAction = createAction<{ clipIds: Clip['id'][] }>('UNFAVORITE_CLIPS_REQUEST');
export const unfavoriteClipsFailureAction = createAction<{ clipIds: Clip['id'][] }>('UNFAVORITE_CLIPS_FAILURE');

export const moveAssetsToBoardRequestAction = createAction<{ assetIds: Asset['id'][]; board: CentralizedClipBoard }>(
  'MOVE_ASSETS_TO_BOARD_REQUEST',
);

export const updateCentralizedClipAssetsAction = createAction<{
  assets: Partial<CentralizedClip['assets']>;
}>('UPDATE_CLIP_ASSETS');

export const setCentralizedClipPDFNumberOfPagesAction = createAction<{
  clipId: Clip['id'];
  pdfPageNumber: number;
}>('SET_CENTRALIZED_CLIP_PDF_PAGE_NUMBER');
