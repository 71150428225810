import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { type ComponentProps, memo } from 'react';

export const actionSheetLabel = tailwindVariants({
  base: 'text-12 font-bold uppercase tracking-wide text-grey-10',
  variants: {
    size: {
      small: 'px-1.5',
      large: 'px-2',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type ActionSheetLabelVariants = VariantProps<typeof actionSheetLabel>;

export type ActionSheetLabelProps = Omit<ComponentProps<'div'>, 'size'> & ActionSheetLabelVariants;

export const ActionSheetLabel = memo(({ className, size = 'small', ...restOfProps }: ActionSheetLabelProps) => {
  return <div className={actionSheetLabel({ size, className })} data-testid="ACTION_SHEET_LABEL" {...restOfProps} />;
});

ActionSheetLabel.displayName = 'DropdownMenuLabel';
