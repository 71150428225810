import { createAction } from '@reduxjs/toolkit';

import { GetCompletedMetadata, Task } from './types';

export const setTaskAction = createAction<Task>('SET_TASK');

export const hideTaskAction = createAction<Pick<Task, 'localTaskId'>>('HIDE_TASK');

export const removeTasksAction = createAction<{ taskIds: Task['localTaskId'][] }>('REMOVE_TASKS');

export const updateTaskMetadataAction = createAction<{
  localTaskId: Task['localTaskId'];
  metadata: Partial<Task['metadata']>;
}>('UPDATE_TASK');

export const markTaskAsCompletedAction = createAction<{
  localTaskId: Task['localTaskId'];
  metadata: GetCompletedMetadata<Task, false>;
}>('MARK_TASK_AS_COMPLETED');

export const markTaskAsFailedAction = createAction<Pick<Task, 'localTaskId'>>('MARK_TASK_AS_FAILED');

export const addRemoteTaskIdAction = createAction<
  Pick<Task, 'localTaskId'> & {
    remoteTaskId: string;
  }
>('UPDATE_TASK_ID');
