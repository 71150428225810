import {
  largeUploadByIdSelector,
  LargeUploadWithS3,
  setUploadCompletedAction,
  setUploadFailedAction,
  Upload,
  uploadedPartsSelector,
  uploadHasUploadInfo,
  UploadPart,
  useUploaderStore,
} from '@air/redux-uploader';
import { sortBy } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { isAbortError } from '../utilities';

export interface CompleteLargeUploadParams {
  uploadId: Upload['id'];
  sendUploadComplete: (upload: LargeUploadWithS3, sortedParts: UploadPart[]) => Promise<void>;
  onUploadFinished: (params: { uploadId: Upload['id'] }) => Promise<void> | void;
  onError: (params: { upload: Upload; error: unknown }) => void;
}

export const useCompleteLargeUpload = () => {
  const store = useUploaderStore();
  const dispatch = useDispatch();

  const completeLargeUpload = useCallback(
    async ({ sendUploadComplete, uploadId, onUploadFinished, onError }: CompleteLargeUploadParams) => {
      const upload = largeUploadByIdSelector(store.getState(), uploadId);
      if (upload && uploadHasUploadInfo(upload)) {
        try {
          const uploadedParts = uploadedPartsSelector(store.getState(), upload.id);
          const sortedParts = sortBy(uploadedParts, 'PartNumber');

          await sendUploadComplete(upload, sortedParts);

          await onUploadFinished({ uploadId });
          dispatch(setUploadCompletedAction({ uploadId }));
        } catch (error) {
          if (!isAbortError(error)) {
            dispatch(setUploadFailedAction({ uploadId: upload.id }));
          }
          onError({ upload, error });
        }
      }
    },
    [dispatch, store],
  );

  return {
    completeLargeUpload,
  };
};
