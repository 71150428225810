import { UploadStatus } from './types';

export const CHUNK_SIZE = 25 * 1024 * 1024;

export const activeUploadStates = [
  UploadStatus.uploading,
  UploadStatus.queued,
  UploadStatus.paused,
  UploadStatus.preparing,
];

export const pausableStates = [UploadStatus.uploading, UploadStatus.queued, UploadStatus.preparing];
