import {
  activeNotCompletedUploadsSelector,
  notStartedUploadsIdsSelector,
  removeUploadsAction,
  resetUploadsAction,
  useUploaderStore,
} from '@air/redux-uploader';
import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { useDeleteUpload, UseDeleteUploadParams } from '../.';

export const useDeleteAllUploads = ({ onDelete }: UseDeleteUploadParams | undefined = {}) => {
  const dispatch = useDispatch();
  const store = useUploaderStore();
  const { deleteUpload } = useDeleteUpload({ onDelete });

  const deleteAllUploads = useCallback(async () => {
    const notStartedUploadsIds = notStartedUploadsIdsSelector(store.getState());
    const startedUploads = activeNotCompletedUploadsSelector(store.getState());
    dispatch(removeUploadsAction({ uploadIds: notStartedUploadsIds }));

    batch(() => {
      startedUploads.map((upload) => deleteUpload(upload));
    });
    dispatch(resetUploadsAction());
  }, [deleteUpload, dispatch, store]);

  return {
    deleteAllUploads,
  };
};
