import { ClipAndBoardListItem } from '@air/api/types';
import { DragStartEvent } from '@dnd-kit/core';

export enum DndItemType {
  kanbanAsset = 'kanbanAsset',
  kanbanDroppableColumn = 'kanbanDroppableColumn',
  kanbanSortableColumn = 'kanbanSortableColumn',
  kanbanFile = 'kanbanFile',
  kanbanBoard = 'kanbanBoard',
  kanbanUpload = 'kanbanUpload',
}

export type DndSortableKanbanItemData = {
  dndType: DndItemType.kanbanAsset | DndItemType.kanbanBoard | DndItemType.kanbanFile | DndItemType.kanbanUpload;
  itemId: string;
  currentKanbanColumnId: string;
  sortableKanbanItemKey: string;
  apiData: ClipAndBoardListItem;
};

export type DndDroppableKanbanColumnData = {
  dndType: DndItemType.kanbanDroppableColumn;
  kanbanColumnId: string;
};

export type DndSortableKanbanColumnData = {
  dndType: DndItemType.kanbanSortableColumn;
  kanbanColumnId: string;
};

export type DndData = DragStartEvent['active']['data']['current'];

export const isDndSortableKanbanItemData = (data?: DndData): data is DndSortableKanbanItemData => {
  if (!data) return false;
  return [DndItemType.kanbanAsset, DndItemType.kanbanBoard, DndItemType.kanbanFile].includes(data.dndType);
};

export const isDndDroppableKanbanColumnData = (data?: DndData): data is DndDroppableKanbanColumnData => {
  if (!data) return false;
  return DndItemType.kanbanDroppableColumn === data.dndType;
};

export const isDndSortableKanbanColumnData = (data?: DndData): data is DndSortableKanbanColumnData => {
  if (!data) return false;
  return DndItemType.kanbanSortableColumn === data.dndType;
};
