import type { VariantProps } from '@air/tailwind-variants';
import { tailwindVariants } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

export const chip = tailwindVariants({
  base: 'inline-flex items-center justify-between gap-1 truncate font-medium',
  variants: {
    appearance: {
      rounded: 'rounded-full',
      rectangular: 'rounded',
    },
    color: {
      black: 'bg-black text-white',
      blue: 'bg-blue-5 text-blue-12',
      green: 'bg-green-7 text-teal-12',
      grey: 'bg-grey-7 text-grey-12',
      'light-blue': 'bg-blue-3 text-blue-12',
      'light-green': 'bg-green-4 text-green-12',
      'light-grey': 'bg-grey-4 text-grey-12',
      'light-orange': 'bg-orange-2 text-orange-12',
      'light-pink': 'bg-pink-2 text-pink-12',
      'light-purple': 'bg-purple-3 text-purple-12',
      'light-red': 'bg-red-4 text-red-12',
      'light-teal': 'bg-teal-4 text-teal-12',
      'light-yellow': 'bg-yellow-3 text-yellow-12',
      orange: 'bg-orange-4 text-orange-12',
      pink: 'bg-pink-4 text-pink-12',
      purple: 'bg-purple-6 text-purple-12',
      red: 'bg-red-7 text-red-12',
      teal: 'bg-teal-6 text-teal-12',
      transparent: 'bg-transparent text-grey-12',
      yellow: 'bg-yellow-6 text-yellow-12',
    },
    size: {
      small: 'h-5 px-1 text-10',
      medium: 'h-6 px-1.5 text-12',
      large: 'h-7 px-1.5 text-16',
    },
  },
  defaultVariants: {
    appearance: 'rectangular',
    color: 'grey',
    size: 'small',
  },
});

export type ChipVariants = VariantProps<typeof chip>;

export type ChipProps = Omit<ComponentPropsWithoutRef<'div'>, 'color' | 'prefix' | 'size'> &
  ChipVariants & {
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    { appearance, children, className, color = 'grey', prefix, size = 'small', suffix, ...restOfProps },
    forwardedRef,
  ) => {
    return (
      <div
        className={chip({ appearance, color, size, className })}
        data-testid="CHIP"
        {...restOfProps}
        ref={forwardedRef}
      >
        {!!prefix && <div className="shrink-0">{prefix}</div>}

        {children}

        {!!suffix && <div className="shrink-0">{suffix}</div>}
      </div>
    );
  },
);

Chip.displayName = 'Chip';
