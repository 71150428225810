import { createReducer } from '@reduxjs/toolkit';

import {
  addUserToBoardAction,
  editBoardSuccessAction,
  moveBoardRequestAction,
  removeUserFromBoardAction,
  resetCentralizedBoardAction,
  setCentralizedBoardAction,
  setCentralizedBoardIdAndTitleAction,
  setCentralizedPublicBoardAction,
} from '~/store/centralizedBoard/actions';

import { CentralizedBoardState } from './types';

const initialState: CentralizedBoardState = {};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(resetCentralizedBoardAction, () => initialState)
    .addCase(
      setCentralizedBoardIdAndTitleAction,
      (
        state,
        {
          payload: {
            board: { id, title },
          },
        },
      ) => {
        state.id = id;
        state.title = title;
        if (state.board && id !== state.board.id) {
          state.board = undefined;
        }
      },
    )
    .addCase(setCentralizedPublicBoardAction, (state, action) => {
      state.board = { ...state.board, ...action.payload.board };
      state.board.library = action.payload.board.library;
      state.id = action.payload.board.id;
      state.title = action.payload.board.title;
    })
    .addCase(setCentralizedBoardAction, (state, action) => {
      state.board = { ...state.board, ...action.payload.board };
      state.board.library = action.payload.board.library;
      state.id = action.payload.board.id;
      state.title = action.payload.board.title;
    })
    .addCase(editBoardSuccessAction, (state, action) => {
      const { board } = action.payload;
      if (state.board?.id === board.id) {
        state.board = {
          ...state.board,
          ...board,
        };
      }
    })
    .addCase(addUserToBoardAction, (state, action) => {
      if (state.board && action.payload.boardId === state.board.id) {
        state.board.hasCurrentUser = true;
      }
    })
    .addCase(removeUserFromBoardAction, (state, action) => {
      if (state.board && action.payload.boardId === state.board.id) {
        state.board.hasCurrentUser = false;
      }
    })
    .addCase(moveBoardRequestAction, (state, action) => {
      const {
        payload: { boards },
      } = action;

      // we want to check if the moved board is the current board the user is viewing
      // if so, let's update it with the latest version (cause it comes updated from the server)
      const movedBoard = boards.find(({ id }) => state.board?.id === id);
      if (movedBoard) {
        state.board = { ...state.board, ...movedBoard };
      }
    });
});
