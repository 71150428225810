import { ChevronRight } from '@air/next-icons';
import { type VariantProps } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';

import { DROPDOWN_MENU_BASE_CLASS_NAME, dropdownMenu } from './DropdownMenu';
import { DROPDOWN_MENU_ITEM_BASE_CLASS_NAME, dropdownMenuItem, DropdownMenuItemProps } from './DropdownMenuItem';

export type DropdownMenuSubTriggerVariant = VariantProps<typeof dropdownMenuItem>;

export type DropdownMenuSubVariant = VariantProps<typeof dropdownMenu>;

export type DropdownMenuSubProps = Pick<
  RadixDropdownMenu.DropdownMenuSubProps,
  'defaultOpen' | 'onOpenChange' | 'open'
> &
  Omit<RadixDropdownMenu.DropdownMenuSubContentProps, 'prefix'> &
  DropdownMenuSubVariant &
  Pick<RadixDropdownMenu.DropdownMenuSubTriggerProps, 'disabled'> &
  Pick<DropdownMenuItemProps, 'description' | 'label' | 'prefix' | 'suffix'> & {
    'data-id'?: string;
  };

export const DropdownMenuSub = ({
  children,
  className,
  defaultOpen,
  description,
  disabled,
  label,
  onOpenChange,
  open,
  prefix,
  size,
  suffix,
  'data-id': dataId,
  ...restOfProps
}: DropdownMenuSubProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <RadixDropdownMenu.Sub defaultOpen={defaultOpen} onOpenChange={onOpenChange} open={open}>
      <RadixDropdownMenu.SubTrigger
        className={size && size !== 'small' ? dropdownMenuItem({ size }) : DROPDOWN_MENU_ITEM_BASE_CLASS_NAME}
        data-testid="DROPDOWN_MENU_SUB_TRIGGER"
        data-id={dataId}
        disabled={disabled}
      >
        {prefix ? (
          <div
            className={classNames(
              'mr-2 flex shrink-0 items-center gap-1',
              hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0',
            )}
          >
            {prefix}
          </div>
        ) : null}

        <div className="flex min-w-0 grow flex-col">
          <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
          {hasDescription ? (
            <span className={classNames('text-pigeon-500', isSmall ? 'text-12' : 'text-14')}>{description}</span>
          ) : null}
        </div>

        <div
          className={classNames(
            'ml-2 flex shrink-0 items-center gap-1',
            hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0',
          )}
        >
          {suffix}
          <ChevronRight className="h-4 w-4" />
        </div>
      </RadixDropdownMenu.SubTrigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.SubContent
          className={
            className || (size && size !== 'small') ? dropdownMenu({ className, size }) : DROPDOWN_MENU_BASE_CLASS_NAME
          }
          data-testid="DROPDOWN_MENU_SUB"
          {...restOfProps}
        >
          {children}
        </RadixDropdownMenu.SubContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Sub>
  );
};
