import styled from 'styled-components';

import { shimmerBackground } from '~/components/ClipStatusInfo/ui';
import { getExtStyles } from '~/utils/FileExtensionUtils';

interface ImageContainerProps {
  borderRadius?: number;
  processing?: boolean;
  ext?: string;
  mediaPlayer?: boolean;
}

export const ImageContainer = styled.div<ImageContainerProps>`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  height: 100%;

  ${({ mediaPlayer }) =>
    mediaPlayer &&
    `
    min-height: calc(100vh * 0.35);
    flex: 1;
  `}

  /** Modal icons a light background, other clip icons have background colors based on ext */
  background-color: ${({ processing, color, ext, theme }) =>
    processing ? shimmerBackground : color === 'default' ? theme.colors.pigeon050 : getExtStyles(ext!).bgColor};
`;
