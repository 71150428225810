import { Upload, uploadByIdSelector, uploadedPartsSelector, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';

import { getChunksCountForLargeFile } from '../utilities';

export const useLargeUploadCanBeCompleted = () => {
  const store = useUploaderStore();

  const largeUploadCanBeCompleted = useCallback(
    (uploadId: Upload['id']) => {
      const upload = uploadByIdSelector(store.getState(), uploadId);

      if (upload) {
        const uploadedChunks = uploadedPartsSelector(store.getState(), uploadId);
        const totalChunks = getChunksCountForLargeFile(upload);

        return uploadedChunks.length === totalChunks;
      }
      return false;
    },
    [store],
  );

  return {
    largeUploadCanBeCompleted,
  };
};
