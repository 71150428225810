import { removeUploadsAction, resetUploadsAction } from '@air/redux-uploader';
import { createReducer } from '@reduxjs/toolkit';

import { setPrivateUploadMetadata, setPrivateUploadsMetadata } from '~/store/privateUploadMetadata/actions';
import { PrivateUploadMetadataState } from '~/store/privateUploadMetadata/types';

const initialState: PrivateUploadMetadataState = {};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPrivateUploadMetadata, (state, { payload: { uploadId, metadata } }) => {
      state[uploadId] = metadata;
    })
    .addCase(setPrivateUploadsMetadata, (state, { payload: { uploadsMetadata } }) => {
      uploadsMetadata.forEach(({ uploadId, metadata }) => {
        state[uploadId] = metadata;
      });
    })
    .addCase(removeUploadsAction, (state, { payload: { uploadIds } }) => {
      uploadIds.forEach((uploadId) => {
        delete state[uploadId];
      });
    })
    .addCase(resetUploadsAction, () => initialState);
});
