import { removeUploadsAction, resetUploadsAction } from '@air/redux-uploader';
import { createReducer } from '@reduxjs/toolkit';

import { setPublicUploadMetadata } from '~/store/publicUploadMetadata/actions';
import { PublicUploadMetadataState } from '~/store/publicUploadMetadata/types';

const initialState: PublicUploadMetadataState = {};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPublicUploadMetadata, (state, { payload: { uploadId, metadata } }) => {
      state[uploadId] = metadata;
    })
    .addCase(removeUploadsAction, (state, { payload: { uploadIds } }) => {
      uploadIds.forEach((uploadId) => {
        delete state[uploadId];
      });
    })
    .addCase(resetUploadsAction, () => initialState);
});
