import { AvatarProps } from '../components/Avatar';

export const getAvatarTextClassName = (size: AvatarProps['size']) => {
  if (!size) {
    return 'text-10 uppercase';
  }

  if (size <= 20) {
    return 'text-8 uppercase';
  } else if (size <= 28) {
    return 'text-10 uppercase';
  } else if (size <= 32) {
    return 'text-12 uppercase';
  } else {
    return 'text-14 uppercase';
  }
};
