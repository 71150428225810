import { connect } from 'formik';
import { FormHTMLAttributes } from 'react';

/** Formik-connected form. */
export const Form = connect<Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'onReset' | 'noValidate'>>(
  ({ formik: { handleReset, handleSubmit }, ...props }) => (
    <form onReset={handleReset} onSubmit={handleSubmit} noValidate {...props} />
  ),
);

Form.displayName = 'Form';

export default Form;
