import { Close, DoubleChevronDown, DoubleChevronUp, Pause, Play } from '@air/next-icons';
import React, { memo } from 'react';

import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { CLOSE_UPLOADS_BUTTON } from '~/constants/testIDs';

export interface TrackingPaneHeaderButtonsProps {
  onResume?: () => void;
  onCancel?: () => void;
  onPause?: () => void;
  onDismiss?: () => void;
  status?: SimpleStatus;
  isUploaderOpen: boolean;
  onToggle: (isOpen: boolean) => void;
  disableCancel?: boolean;
}

export const TrackingPaneHeaderButtons = memo(
  ({
    onDismiss,
    onPause,
    onResume,
    status,
    onCancel,
    isUploaderOpen,
    onToggle,
    disableCancel,
  }: TrackingPaneHeaderButtonsProps) => {
    return (
      <>
        {onPause && status === SimpleStatus.progressing && <PaneButton label="Pause" Icon={Pause} onClick={onPause} />}

        {onResume && status === SimpleStatus.paused && <PaneButton label="Resume" Icon={Play} onClick={onResume} />}

        {isUploaderOpen ? (
          <PaneButton label="Minimize" Icon={DoubleChevronDown} onClick={() => onToggle(false)} />
        ) : (
          <PaneButton label="Expand" Icon={DoubleChevronUp} onClick={() => onToggle(true)} />
        )}

        {onDismiss && (disableCancel || status === SimpleStatus.completed) && (
          <PaneButton label="Close" Icon={Close} onClick={onDismiss} testId={CLOSE_UPLOADS_BUTTON} />
        )}

        {onCancel && status !== SimpleStatus.completed && <PaneButton label="Cancel" Icon={Close} onClick={onCancel} />}
      </>
    );
  },
);

TrackingPaneHeaderButtons.displayName = 'TrackingPaneHeaderButtons';
