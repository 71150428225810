import { Fragment, type ReactNode } from 'react';

import { ActionSheetItem, ActionSheetItemProps, ActionSheetItemVariant } from '../ActionSheetItem';
import { ActionSheetLabel } from '../ActionSheetLabel';
import { ActionSheetSeparator } from '../ActionSheetSeparator';
import { ActionSheetSub } from '../ActionSheetSub';

export type ActionSheetOptionBase = {
  id: string;
};

export type ActionSheetOptionChildren = ActionSheetOptionBase & {
  children: ReactNode;
  type: 'children';
};

export type ActionSheetOptionRadioGroup = ActionSheetOptionBase & {
  options?: ActionSheetOptionRadioItem[];
  type: 'radio-group';
};

export type ActionSheetOptionRadioItem = ActionSheetOptionBase &
  Omit<ActionSheetOptionItem, 'suffix' | 'type'> & {
    type: 'radio-item';
    value: string;
  };

export type ActionSheetOptionSeparator = ActionSheetOptionBase & {
  className?: string;
  type: 'separator';
};

export type ActionSheetOptionTitle = ActionSheetOptionBase & {
  className?: string;
  label: string;
  type: 'title';
};

export type ActionSheetOptionSub = Omit<ActionSheetOptionItem, 'suffix' | 'type'> & {
  options: ActionSheetOption[];
  type: 'sub';
};

export type ActionSheetOption =
  | ActionSheetOptionChildren
  | ActionSheetOptionItem
  | ActionSheetOptionRadioGroup
  | ActionSheetOptionRadioItem
  | ActionSheetOptionSeparator
  | ActionSheetOptionTitle
  | ActionSheetOptionSub;

export type ActionSheetOptionItem = ActionSheetOptionBase &
  Pick<ActionSheetItemProps, 'disabled'> & {
    className?: string;
    description?: string;
    label: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    shortcut?: string[];
    suffix?: ReactNode;
    type: 'item';
  };

export type RenderActionSheetItemsParams = {
  options?: ActionSheetOption[];
  size?: ActionSheetItemVariant['size'];
};

export const renderActionSheetItems = ({ options, size = 'small' }: RenderActionSheetItemsParams) => {
  if (!options) return null;

  return options.map((option, index) => {
    switch (option.type) {
      case 'children':
        return <Fragment key={option.id || index}>{option.children}</Fragment>;
      case 'item':
        return (
          <ActionSheetItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            onSelect={option.onSelect}
            prefix={option.prefix}
            shortcut={option.shortcut}
            size={size}
            suffix={option.suffix}
            key={option.id || index}
          />
        );
        return null;
      case 'radio-item':
        return null;
      case 'separator':
        return <ActionSheetSeparator className={option.className} data-id={option.id} key={option.id || index} />;
      case 'sub':
        return (
          <ActionSheetSub className={option.className} data-id={option.id} key={option.id || index}>
            {renderActionSheetItems({
              options: option.options,
              size,
            })}
          </ActionSheetSub>
        );
      case 'title':
        return (
          <ActionSheetLabel className={option.className} data-id={option.id} key={option.id || index} size={size}>
            {option.label}
          </ActionSheetLabel>
        );
      default:
        return null;
    }
  });
};
