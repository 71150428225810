import * as ReachAlertDialog from '@reach/alert-dialog';
import { animated, config, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import type { ComponentProps } from 'react';

export type AlertModalSize = 'small' | 'medium' | 'large';

const ALERT_MODAL_SIZE_CLASS_NAME: { [key in AlertModalSize]: string } = {
  small: 'w-[400px]',
  medium: 'w-[496px]',
  large: 'w-[800px]',
};

const AnimatedOverlay = animated(ReachAlertDialog.AlertDialogOverlay);
const AnimatedContent = animated(ReachAlertDialog.AlertDialogContent);

export type AlertModalProps = Pick<
  ReachAlertDialog.AlertDialogProps,
  'children' | 'isOpen' | 'leastDestructiveRef' | 'onDismiss'
> &
  Pick<ComponentProps<'div'>, 'className'> & {
    overlayClassName?: string;
    size?: AlertModalSize;
  };

export const AlertModal = ({
  children,
  className,
  isOpen,
  leastDestructiveRef,
  onDismiss,
  overlayClassName,
  size = 'medium',
  ...restOfProps
}: AlertModalProps) => {
  const transitions = useTransition(isOpen, {
    config: config.stiff,
    enter: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 50 },
    leave: { opacity: 0, y: 10 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <AnimatedOverlay
          className={classNames(
            'fixed inset-0 overflow-auto bg-black/75 py-8 transition-opacity',
            isOpen ? 'opacity-100' : 'opacity-0',
            overlayClassName,
          )}
          onDismiss={onDismiss}
          leastDestructiveRef={leastDestructiveRef}
          style={{ opacity: styles.opacity }}
        >
          <AnimatedContent
            className={classNames(
              'relative mx-auto mt-[calc(10vw-32px)] min-h-[100px] max-w-[100vw] rounded-md bg-surface-1 p-6 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-9',
              ALERT_MODAL_SIZE_CLASS_NAME[size],
              className,
            )}
            style={{ transform: styles.y.to((value) => `translate3d(0px, ${value}px, 0px)`) }}
            {...restOfProps}
          >
            {children}
          </AnimatedContent>
        </AnimatedOverlay>
      ),
  );
};
