import { Upload, uploadByIdSelector, uploadHasUploadInfo, UploadStatus, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';

export const useCanLargeChunkBeUploaded = () => {
  const store = useUploaderStore();

  const canLargeChunkBeUploaded = useCallback(
    (uploadId: Upload['id']) => {
      const upload = uploadByIdSelector(store.getState(), uploadId);
      return !!upload && uploadHasUploadInfo(upload) && upload.status !== UploadStatus.paused;
    },
    [store],
  );

  return {
    canLargeChunkBeUploaded,
  };
};
