import { TXProp } from '@air/zephyr';
import { memo, ReactNode, useMemo } from 'react';

import { StatusAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import {
  TrackingPaneHeaderButtons,
  TrackingPaneHeaderButtonsProps,
} from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/TrackingPaneHeaderButtons';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { UPLOADER_TITLE } from '~/constants/testIDs';

export enum SimpleStatus {
  completed = 'completed',
  paused = 'paused',
  errored = 'errored',
  progressing = 'progressing',
}

export interface UploaderPaneHeaderProps extends TrackingPaneHeaderButtonsProps {
  title: ReactNode;
  subtitle: ReactNode;
  tx?: TXProp;
  disableCancel?: boolean;
  status: SimpleStatus;
}

export const FileStatusTrackingPaneHeader = memo(
  ({
    isUploaderOpen,
    onToggle,
    status,
    title,
    subtitle,
    tx,
    disableCancel,
    onCancel,
    onDismiss,
    onPause,
    onResume,
  }: UploaderPaneHeaderProps) => {
    const containerStyles = useMemo(
      () => ({
        py: 12,
        px: 16,
        /** We're trying to vertically align the center of the buttons with the center of the header */
        '& > *:first-child': { py: 4 },
        borderRadius: isUploaderOpen ? 0 : 3,
        ...tx,
      }),
      [isUploaderOpen, tx],
    );

    return (
      <PaneItem
        tx={containerStyles}
        avatar={<StatusAvatar status={status} />}
        title={
          <StatusTrackingTitle data-testid={UPLOADER_TITLE} className="font-semibold">
            {title}
          </StatusTrackingTitle>
        }
        subtitle={<StatusTrackingSubtitle>{subtitle}</StatusTrackingSubtitle>}
        buttons={
          <TrackingPaneHeaderButtons
            status={status}
            onCancel={onCancel}
            onResume={onResume}
            disableCancel={disableCancel}
            onPause={onPause}
            isUploaderOpen={isUploaderOpen}
            onDismiss={onDismiss}
            onToggle={onToggle}
          />
        }
      />
    );
  },
);

FileStatusTrackingPaneHeader.displayName = 'FileStatusTrackingPaneHeader';
