import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { type ComponentProps, type ReactNode } from 'react';

export const actionSheetItem = tailwindVariants({
  base: 'flex h-8 w-full shrink-0 cursor-pointer items-center gap-2 rounded px-2 text-left text-inherit outline-none radix-disabled:cursor-not-allowed radix-disabled:bg-transparent radix-disabled:opacity-50 radix-highlighted:bg-grey-4 hover:bg-grey-4',
  variants: {
    description: {
      false: 'items-center py-0',
      true: 'items-start py-1.5',
    },
    size: {
      small: 'h-8',
      large: 'h-9',
    },
  },
  compoundVariants: [
    {
      description: true,
      size: 'small',
      className: 'h-auto',
    },
    {
      description: true,
      size: 'large',
      className: 'h-auto',
    },
  ],
  defaultVariants: {
    description: false,
    size: 'small',
  },
});

export type ActionSheetItemVariant = VariantProps<typeof actionSheetItem>;

export type ActionSheetItemProps = Omit<ComponentProps<'button'>, 'onClick' | 'prefix'> &
  Omit<ActionSheetItemVariant, 'description'> & {
    description?: ReactNode;
    label?: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    suffix?: ReactNode;
    shortcut?: string[];
  };

export const ActionSheetItem = ({
  children,
  className,
  description,
  label,
  onSelect,
  prefix,
  shortcut,
  size = 'small',
  suffix,
  ...restOfProps
}: ActionSheetItemProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <button
      className={actionSheetItem({ description: !!description, size, className })}
      data-testid="ACTION_SHEET_ITEM"
      onClick={onSelect}
      {...restOfProps}
    >
      {!!children ? (
        children
      ) : (
        <>
          {!!prefix ? (
            <div className={classNames('mr-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
              {prefix}
            </div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
            {hasDescription ? (
              <span className={classNames('text-grey-10', isSmall ? 'text-12' : 'text-14')}>{description}</span>
            ) : null}
          </div>

          {!!suffix ? (
            <div className={classNames('ml-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
              {suffix}
            </div>
          ) : null}
          {!!shortcut ? (
            <div className="ml-2 flex shrink-0 items-center">
              {shortcut.map((key, index) => (
                <kbd
                  className="mr-0.5 flex h-4 w-4 items-center justify-center rounded bg-grey-3 text-10 uppercase text-grey-8 last:mr-0"
                  key={index}
                >
                  {key}
                </kbd>
              ))}
            </div>
          ) : null}
        </>
      )}
    </button>
  );
};
