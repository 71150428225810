import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { Children } from 'react';

export const CONTEXT_MENU_BASE_CLASS_NAME =
  'w-[216px] flex max-h-[var(--radix-context-menu-content-available-height)] w-[216px] min-w-[var(--radix-popper-anchor-width)] flex-col gap-2 overflow-y-auto rounded bg-surface-1 p-2 text-grey-12 shadow-popover outline-none radix-side-right:animate-slide-up radix-side-left:animate-slide-down dark:border dark:border-grey-5 z-10';

export type ContextMenuProps = RadixContextMenu.ContextMenuContentProps & {
  trigger: RadixContextMenu.ContextMenuTriggerProps['children'];
  onOpenChange?: RadixContextMenu.ContextMenuProps['onOpenChange'];
};

export const ContextMenu = ({
  children,
  trigger,
  onOpenChange,
  className,
  collisionPadding = 8,
  ...restOfProps
}: ContextMenuProps) => {
  return (
    <RadixContextMenu.Root onOpenChange={onOpenChange} dir="ltr" modal={false}>
      <RadixContextMenu.Trigger asChild>{trigger}</RadixContextMenu.Trigger>
      {!!Children.count(children) && (
        <RadixContextMenu.Portal>
          <RadixContextMenu.Content
            className={
              className ? tailwindMerge(CONTEXT_MENU_BASE_CLASS_NAME, className) : CONTEXT_MENU_BASE_CLASS_NAME
            }
            data-testid="CONTEXT_MENU"
            collisionPadding={collisionPadding}
            {...restOfProps}
          >
            {children}
          </RadixContextMenu.Content>
        </RadixContextMenu.Portal>
      )}
    </RadixContextMenu.Root>
  );
};
