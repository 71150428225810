import { Board, Clip, ClipAndBoardListItem } from '@air/api/types';
import { createReducer } from '@reduxjs/toolkit';
import { isUndefined } from 'lodash';

import {
  kanbanColumnFetcherSyncedAction,
  kanbanItemDragCancelAction,
  kanbanItemDragEndAction,
  kanbanItemDragStartAction,
  kanbanItemPositionSuccessAction,
  removeKanbanItemsAction,
  resetKanbanManagerAction,
  setGroupedItemsResponseInKanbanManagerAction,
  updateKanbanColumnWithNewDataAction,
} from '~/store/kanbanManager/actions';
import { KanbanManagerState } from '~/store/kanbanManager/types';
import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';
import { getSortableKanbanItemKey } from '~/utils/getDndKeys';

const initialState: KanbanManagerState = {
  columnsClone: null,
  columns: {},
};

const convertApiDataToDnDItem = ({
  apiData,
  kanbanColumnId,
}: {
  apiData: ClipAndBoardListItem;
  kanbanColumnId: string;
}): DndSortableKanbanItemData => {
  const dndType =
    apiData.type === 'board'
      ? DndItemType.kanbanBoard
      : apiData.type === 'file'
      ? DndItemType.kanbanFile
      : DndItemType.kanbanAsset;
  const itemId = apiData.type === 'board' ? (apiData.data as Board).id : (apiData.data as Clip).assetId;
  return {
    dndType,
    itemId,
    currentKanbanColumnId: kanbanColumnId,
    sortableKanbanItemKey: getSortableKanbanItemKey(apiData),
    apiData,
  };
};

const extractDndItemsFromApiData = ({
  clipsAndBoardsListItems = [],
  kanbanColumnId,
}: {
  clipsAndBoardsListItems?: ClipAndBoardListItem[];
  kanbanColumnId: string;
}): DndSortableKanbanItemData[] =>
  clipsAndBoardsListItems.map((apiData) => convertApiDataToDnDItem({ apiData, kanbanColumnId }));

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetKanbanManagerAction, () => initialState)
    .addCase(setGroupedItemsResponseInKanbanManagerAction, (state, action) => {
      const { columnsWithInitialData } = action.payload;
      for (const [kanbanColumnId, initialColumnResponse] of Object.entries(columnsWithInitialData)) {
        if (!state.columns[kanbanColumnId]) {
          state.columns[kanbanColumnId] = { items: [], totalItems: 0 };
        }
        state.columns[kanbanColumnId].items = extractDndItemsFromApiData({
          clipsAndBoardsListItems: initialColumnResponse.data.data,
          kanbanColumnId,
        });
        state.columns[kanbanColumnId].totalItems = initialColumnResponse.data.total;
      }
    })
    .addCase(updateKanbanColumnWithNewDataAction, (state, action) => {
      for (const [kanbanColumnId, clipsAndBoardsListItems] of Object.entries(action.payload.columnsToUpdate)) {
        if (!state.columns[kanbanColumnId]) return;
        state.columns[kanbanColumnId].items = extractDndItemsFromApiData({
          clipsAndBoardsListItems: clipsAndBoardsListItems.data,
          kanbanColumnId,
        });
        if (!isUndefined(clipsAndBoardsListItems.total)) {
          state.columns[kanbanColumnId].totalItems = clipsAndBoardsListItems.total;
        }
        if (!action.payload.shouldSkipSWRSync) {
          state.columns[kanbanColumnId].unsyncedItems = {
            data: clipsAndBoardsListItems.data,
            total: isUndefined(clipsAndBoardsListItems.total)
              ? state.columns[kanbanColumnId].totalItems
              : clipsAndBoardsListItems.total,
          };
        }
      }
    })
    .addCase(kanbanItemDragStartAction, (state) => {
      state.columnsClone = { ...state.columns };
    })
    .addCase(kanbanItemDragEndAction, (state, action) => {
      const { updatedColumnItems } = action.payload;
      for (const [columnId, items] of Object.entries(updatedColumnItems)) {
        state.columns[columnId].items = items.data;
        state.columns[columnId].totalItems = items.total;
        state.columns[columnId].unsyncedItems = {
          data: items.data.map((i) => i.apiData),
          total: items.total,
        };
      }
    })
    .addCase(kanbanItemDragCancelAction, (state) => {
      state.columns = { ...state.columnsClone };
      state.columnsClone = null;
    })
    .addCase(kanbanItemPositionSuccessAction, (state) => {
      state.columnsClone = null;
    })
    .addCase(kanbanColumnFetcherSyncedAction, (state, action) => {
      state.columns[action.payload.kanbanColumnId].unsyncedItems = undefined;
    })
    .addCase(removeKanbanItemsAction, (state, { payload: { itemIds } }) => {
      for (const [columnId, column] of Object.entries(state.columns)) {
        itemIds.forEach((itemId) => {
          const itemIndex = column.items.findIndex((item) => item.itemId === itemId);
          if (itemIndex !== -1) {
            state.columns[columnId].items.splice(itemIndex, 1);
            state.columns[columnId].totalItems -= 1;

            state.columns[columnId].unsyncedItems = {
              data: state.columns[columnId].items.map((i) => i.apiData),
              total: state.columns[columnId].totalItems,
            };
          }
        });
      }
    });
});
