import { tailwindMerge } from '@air/tailwind-variants';
import { DialogContent, type DialogContentProps } from '@reach/dialog';
import { motion, type MotionProps, useReducedMotion, type Variants } from 'framer-motion';
import { type ComponentPropsWithoutRef } from 'react';

const ACTION_SHEET_CONTENT_VARIANTS: Variants = {
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 300,
  },
  initial: {
    opacity: 0,
    y: 300,
  },
};

const ACTION_SHEET_CONTENT_REDUCED_VARIANTS: Variants = {
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 0,
  },
  initial: {
    opacity: 0,
    y: 0,
  },
};

export type ActionSheetContentProps = Omit<MotionProps, 'children'> &
  ComponentPropsWithoutRef<'div'> &
  Pick<DialogContentProps, 'children'> & {
    onClose: () => void;
  };

export const ActionSheetContent = ({ children, className, onClose, ...restOfProps }: ActionSheetContentProps) => {
  const shouldReduceMotion = useReducedMotion();

  return (
    <motion.div
      animate="animate"
      className={tailwindMerge(
        'fixed inset-x-0 -bottom-[5vh] top-auto flex max-h-[85vh] w-full rounded-t-lg bg-surface-1 pb-[5vh]',
        className,
      )}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      exit="exit"
      initial="initial"
      onDragEnd={(_event, info) => {
        /**
         * When `drag` ends and the action sheet has an offset greater
         * than `40px`, the action sheet will close.
         */
        if (info.offset.y > 40) {
          onClose();
        }
      }}
      transition={{ type: 'spring', bounce: 0.2, duration: 0.4 }}
      variants={shouldReduceMotion ? ACTION_SHEET_CONTENT_REDUCED_VARIANTS : ACTION_SHEET_CONTENT_VARIANTS}
      {...restOfProps}
    >
      <DialogContent className="m-0 flex w-full flex-col gap-2 bg-transparent p-2">{children}</DialogContent>
    </motion.div>
  );
};
