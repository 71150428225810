import { Auth as AuthAPI } from '@air/api';
import {
  callOnFullAccountUserPool,
  type LoginViaEmailParams,
  type TrueCognitoType,
  type UserCognitoInfo,
} from '@air/utils-auth';
import { reportErrorToBugsnag } from '@air/utils-error';
import Link from 'next/link';

import { Routes } from '~/constants/routes';

/**
 * @deprecated Use `loginViaEmail` from `@air/utils-auth` instead.
 */
export const loginViaEmail = async ({ username, password, cognitoUser }: LoginViaEmailParams) =>
  callOnFullAccountUserPool(async () => {
    try {
      let user = cognitoUser as TrueCognitoType | undefined;

      if (!user) {
        user = await AuthAPI.signIn(username, password);
      }

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return { cognitoUser: user, updatePassword: true };
      } else {
        const userSession = user.getSignInUserSession();
        if (!userSession) throw new Error('no user session');

        const { payload } = userSession.getIdToken();
        const token = userSession.getIdToken().getJwtToken();
        const userInfo: UserCognitoInfo = {
          sub: payload.sub,
          email: payload.email,
        };

        return { cognitoUser: user, token, userInfo };
      }
    } catch (_error) {
      const error = reportErrorToBugsnag({ error: _error, context: 'Error logging in with Cognito' });

      if (error.message.includes('PasswordResetRequiredException')) {
        return { resetPassword: true };
      }
      throw error;
    }
  });

const extractIdentityProvider = (rawErrorMessage: string) => {
  // workaround to getting structured data back on an error returned from a trigger running behind cognito
  const errorMessageParts = rawErrorMessage.split('::');

  if (errorMessageParts.length <= 1) {
    // assume default IdP if message isn't formatted correctly
    return 'default';
  }

  const internalErrorMessagePart = rawErrorMessage.split('::')[1];

  // strip off period appended to message by cognito
  const serializedInternalError = internalErrorMessagePart.substring(0, internalErrorMessagePart.length - 1);

  const { identityProvider }: { identityProvider: string } = JSON.parse(serializedInternalError);

  return identityProvider;
};

const existingAccountLink = (identityProvider: string) => {
  switch (identityProvider) {
    case 'google':
      return (
        <button className="text-blue-9 underline" onClick={() => AuthAPI.googleSignIn()}>
          Login with Google →
        </button>
      );
    case 'apple':
      return (
        <button className="text-blue-9 underline" onClick={() => AuthAPI.appleSignIn()}>
          Login with Apple →
        </button>
      );
    default:
      return <Link href={Routes.auth.login}>Log in →</Link>;
  }
};

interface ExistingAccountMessageWithAuthLinkProps {
  /** Raw error returned from server */
  rawErrorMessage?: string;
  /** identityProvider of the existing account */
  identityProvider?: string;
  /** message to prefix in result */
  messagePrefix: string;
}

export const ExistingAccountMessageWithAuthLink = ({
  rawErrorMessage,
  identityProvider,
  messagePrefix,
}: ExistingAccountMessageWithAuthLinkProps) => {
  const idp = identityProvider || extractIdentityProvider(rawErrorMessage || '');
  return (
    <>
      {messagePrefix}
      {messagePrefix.endsWith('.') ? '' : '.'} {existingAccountLink(idp)}
    </>
  );
};

export const PASSWORD_REQS_STRING = '8+ characters, one number, and one capital letter';
