import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEffect, useState } from 'react';

export const DROPDOWN_MENU_BASE_CLASS_NAME =
  'flex max-h-[var(--radix-dropdown-menu-content-available-height)] w-[216px] min-w-[var(--radix-popper-anchor-width)] flex-col gap-2 overflow-y-auto rounded bg-surface-1 p-2 text-grey-12 shadow-popover outline-none radix-side-bottom:animate-slide-up radix-side-top:animate-slide-down dark:border dark:border-grey-5 z-10';

export const dropdownMenu = tailwindVariants({
  base: DROPDOWN_MENU_BASE_CLASS_NAME,
  variants: {
    size: {
      small: 'w-[216px]',
      large: 'w-[240px]',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type DropdownMenuVariant = VariantProps<typeof dropdownMenu>;

export type DropdownMenuProps = Pick<RadixDropdownMenu.DropdownMenuProps, 'onOpenChange' | 'open'> &
  Pick<RadixDropdownMenu.DropdownMenuTriggerProps, 'disabled'> &
  RadixDropdownMenu.MenuContentProps &
  DropdownMenuVariant & {
    trigger?: RadixDropdownMenu.DropdownMenuTriggerProps['children'];
    container?: RadixDropdownMenu.MenuPortalProps['container'];
  };

export const DropdownMenu = ({
  align = 'start',
  alignOffset,
  children,
  className,
  collisionPadding = 8,
  disabled,
  loop,
  onOpenChange,
  open = false,
  side = 'bottom',
  sideOffset = 8,
  size = 'small',
  trigger,
  container,
  ...restOfProps
}: DropdownMenuProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(open);

  useEffect(() => {
    setIsMenuOpened(open);
  }, [open]);

  return (
    <RadixDropdownMenu.Root
      data-theme="dark"
      dir="ltr"
      modal={false}
      onOpenChange={(open) => {
        setIsMenuOpened(open);
        onOpenChange?.(open);
      }}
      open={isMenuOpened}
    >
      <RadixDropdownMenu.Trigger asChild disabled={disabled}>
        {trigger}
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal container={container}>
        <RadixDropdownMenu.Content
          align={align}
          alignOffset={alignOffset}
          className={
            className || (size && size !== 'small') ? dropdownMenu({ size, className }) : DROPDOWN_MENU_BASE_CLASS_NAME
          }
          collisionPadding={collisionPadding}
          data-testid="DROPDOWN_MENU"
          loop={loop}
          side={side}
          sideOffset={sideOffset}
          {...restOfProps}
        >
          {children}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
