import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { type ReactNode } from 'react';

export const DROPDOWN_MENU_ITEM_BASE_CLASS_NAME =
  'flex shrink-0 cursor-pointer rounded px-1.5 text-inherit outline-none radix-disabled:cursor-not-allowed radix-disabled:bg-transparent radix-disabled:opacity-50 radix-highlighted:bg-grey-4 hover:bg-grey-4 items-center min-h-[32px]';

export const dropdownMenuItem = tailwindVariants({
  base: DROPDOWN_MENU_ITEM_BASE_CLASS_NAME,
  variants: {
    description: {
      false: 'items-center py-0',
      true: 'items-start py-1.5',
    },
    size: {
      small: 'min-h-[32px] text-14',
      large: 'min-h-[36px] text-16',
    },
  },
  compoundVariants: [
    {
      description: true,
      size: 'small',
      className: 'h-auto',
    },
    {
      description: true,
      size: 'large',
      className: 'h-auto',
    },
  ],
  defaultVariants: {
    description: false,
    size: 'small',
  },
});

export type DropdownMenuItemVariant = VariantProps<typeof dropdownMenuItem>;

export type DropdownMenuItemProps = Omit<RadixDropdownMenu.MenuItemProps, 'prefix' | 'size'> &
  Omit<DropdownMenuItemVariant, 'description'> & {
    description?: ReactNode;
    label?: ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
    shortcut?: string[];
  };

export const DropdownMenuItem = ({
  children,
  className,
  description,
  label,
  prefix,
  shortcut,
  size = 'small',
  suffix,
  ...restOfProps
}: DropdownMenuItemProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <RadixDropdownMenu.Item
      className={
        className || description || (size && !isSmall)
          ? dropdownMenuItem({ className, description: !!description, size })
          : DROPDOWN_MENU_ITEM_BASE_CLASS_NAME
      }
      data-testid="DROPDOWN_MENU_ITEM"
      {...restOfProps}
    >
      {!!children ? (
        children
      ) : (
        <>
          {!!prefix ? (
            <div
              className={classNames(
                'mr-2 flex shrink-0 items-center',
                hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0',
              )}
            >
              {prefix}
            </div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
            {hasDescription ? (
              <span className={classNames('text-grey-10', isSmall ? 'text-12' : 'text-14')}>{description}</span>
            ) : null}
          </div>

          {!!suffix ? (
            <div
              className={classNames(
                'ml-2 flex shrink-0 items-center',
                hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0',
              )}
            >
              {suffix}
            </div>
          ) : null}
          {!!shortcut ? (
            <div className="ml-2 flex shrink-0 items-center">
              {shortcut.map((key, index) => (
                <kbd
                  className="mr-0.5 flex h-4 w-4 items-center justify-center rounded bg-grey-3 text-10 uppercase text-grey-8 last:mr-0"
                  key={index}
                >
                  {key}
                </kbd>
              ))}
            </div>
          ) : null}
        </>
      )}
    </RadixDropdownMenu.Item>
  );
};
