import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { memo } from 'react';

export const CONTEXT_MENU_SEPARATOR_BASE_CLASS_NAME = 'h-px bg-grey-4 -mx-2 shrink-0';

export type ContextMenuSeparatorProps = RadixContextMenu.ContextMenuSeparatorProps;

export const ContextMenuSeparator = memo(({ className, ...restOfProps }: ContextMenuSeparatorProps) => {
  return (
    <RadixContextMenu.Separator
      className={
        className
          ? tailwindMerge(CONTEXT_MENU_SEPARATOR_BASE_CLASS_NAME, className)
          : CONTEXT_MENU_SEPARATOR_BASE_CLASS_NAME
      }
      data-testid="CONTEXT_MENU_SEPARATOR"
      {...restOfProps}
    />
  );
});

ContextMenuSeparator.displayName = 'ContextMenuSeparator';
