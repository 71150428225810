import { Spinner } from '@air/primitive-spinner';
import { memo } from 'react';

import { SELECT_LOADING } from '~/constants/testIDs';

export const SelectLoadingOption = memo(() => {
  return (
    <li className="flex w-full items-center justify-start" data-testid={SELECT_LOADING}>
      <Spinner className="h-3 w-3 text-grey-12" />
      <div className="ml-1.5 text-14 font-medium text-grey-10">Loading</div>
    </li>
  );
});

SelectLoadingOption.displayName = 'SelectLoadingOption';
