import { createReducer } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import {
  addRemoteTaskIdAction,
  hideTaskAction,
  markTaskAsCompletedAction,
  markTaskAsFailedAction,
  removeTasksAction,
  setTaskAction,
  updateTaskMetadataAction,
} from './actions';
import { TasksState } from './types';

export const initialState = {
  tasks: [],
};

export const reducer = createReducer<TasksState>(initialState, (builder) => {
  builder
    .addCase(setTaskAction, (state, { payload }) => {
      const index = state.tasks.findIndex((task) => task.localTaskId === payload.localTaskId);

      if (index > -1) {
        state.tasks[index] = payload;
      } else {
        state.tasks.push(payload);
      }
    })
    .addCase(removeTasksAction, (state, { payload: { taskIds } }) => {
      const index = state.tasks.findIndex((task) => taskIds.includes(task.localTaskId));

      if (index > -1) {
        state.tasks.splice(index, 1);
      }
    })
    .addCase(updateTaskMetadataAction, (state, { payload: { metadata, localTaskId } }) => {
      state.tasks.forEach((task) => {
        if (task.localTaskId === localTaskId) {
          task.metadata = merge(task.metadata, metadata);
        }
      });
    })
    .addCase(markTaskAsCompletedAction, (state, { payload: { localTaskId, metadata } }) => {
      state.tasks.forEach((task) => {
        if (task.localTaskId === localTaskId) {
          task.status = 'completed';
          task.metadata = merge(task.metadata, metadata);
        }
      });
    })
    .addCase(markTaskAsFailedAction, (state, { payload: { localTaskId } }) => {
      state.tasks.forEach((task) => {
        if (task.localTaskId === localTaskId) {
          task.status = 'error';
        }
      });
    })
    .addCase(addRemoteTaskIdAction, (state, { payload: { localTaskId, remoteTaskId } }) => {
      const index = state.tasks.findIndex((task) => task.localTaskId === localTaskId);

      if (index > -1) {
        state.tasks[index].remoteTaskId = remoteTaskId;
      }
    })
    .addCase(hideTaskAction, (state, { payload: { localTaskId } }) => {
      state.tasks.forEach((task) => {
        if (task.localTaskId === localTaskId) {
          task.hidden = true;
        }
      });
    });
});
