import { noop } from 'lodash';

import { OnProgressParameter, UploadFileParams, UploadFileResponse } from './types';
import { USER_ABORTED_UPLOAD } from './utils';

export const uploadFile = async ({
  file,
  url,
  contentType,
  onProgress,
  onStart = noop,
  returnTag,
}: UploadFileParams): Promise<UploadFileResponse> => {
  const xhr: XMLHttpRequest = new XMLHttpRequest();
  const abort = () => {
    xhr.abort();
  };
  return new Promise((resolve, reject) => {
    xhr.open('PUT', url, true);
    xhr.upload.onprogress = (evt) => {
      const param: OnProgressParameter = { abort };

      if (evt.lengthComputable) {
        param.progress = evt.loaded;
      }

      onProgress?.(param);
    };
    xhr.onerror = reject;
    xhr.onabort = () => reject(new Error(USER_ABORTED_UPLOAD));
    xhr.onload = () => {
      if (returnTag) {
        const xhrResponseHeaderETag = xhr.getResponseHeader('ETag');

        /**
         * We wnat to be sure there is an ETag header if we're requiring one. We had issues where certain users we're getting a successful upload
         * but no `ETag` header was present. throwing here will force the upload to retry that part again
         *
         * @see https://air-labs-team.slack.com/archives/CKE8SK3M1/p1667488801846359
         */
        if (xhrResponseHeaderETag) {
          resolve({
            ETag: xhrResponseHeaderETag,
          });
        } else {
          reject(new Error('Missing ETag for upload'));
        }
      } else {
        resolve({});
      }
    };
    xhr.setRequestHeader('Content-Type', contentType);
    xhr.send(file);
    onStart(xhr);
  });
};
