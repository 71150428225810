import { css } from 'styled-components';

import ShimmerBackground from '~/assets/gradient.png';

export const shimmerBackground = css`
  background: ${({ theme }) => theme.colors.pigeon100};
  background-size: 400% 400%;
  background-image: url(${ShimmerBackground.src});
  animation: shimmer 2s infinite cubic-bezier(0.14, 0.38, 0.89, 0.65);

  @keyframes shimmer {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0 0;
    }
  }
`;
