import { Clip } from '@air/api/types';

import { FileExtensionName, fileExtensionStylingMap, genericFile } from '~/components/FileExtensionPreview/types';

export const getExtStyles = (ext: string) => {
  const normalizedExt = ext?.toLowerCase() || ''; // to support e.g. JPG
  return normalizedExt in fileExtensionStylingMap
    ? fileExtensionStylingMap[normalizedExt as FileExtensionName]
    : genericFile;
};

export const getFilename = ({ title, importedName, ext }: Pick<Clip, 'title' | 'importedName' | 'ext'>) =>
  (title || importedName) + (ext ? `.${ext}` : '');
