import { Board, PublicBoard } from '@air/api/types';

import { BoardPageBoard } from './types';

/**
 * These two functions are purely temporary until permissions are removed from Board and PublicBoard
 * and then they can be removed.
 */
export const isPublicBoard = (board: BoardPageBoard): board is PublicBoard => !!board;

export const isPrivateBoard = (board: BoardPageBoard): board is Board => !!board;
