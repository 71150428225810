import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import classNames from 'classnames';
import { type ReactNode } from 'react';

export const CONTEXT_MENU_ITEM_BASE_CLASS_NAME =
  'min-h-[32px] text-14 flex shrink-0 cursor-pointer rounded px-1.5 text-inherit outline-none radix-disabled:cursor-not-allowed radix-disabled:bg-transparent radix-disabled:opacity-50 radix-highlighted:bg-grey-4 hover:bg-grey-4 items-center min-h-[32px]';

export const contextMenuItem = tailwindVariants({
  base: CONTEXT_MENU_ITEM_BASE_CLASS_NAME,
  variants: {
    description: {
      false: 'items-center py-0',
      true: 'items-start py-1.5',
    },
  },
  defaultVariants: {
    description: false,
  },
});

export type ContextMenuItemVariant = VariantProps<typeof contextMenuItem>;

export type ContextMenuItemProps = Omit<RadixContextMenu.ContextMenuItemProps, 'prefix' | 'size'> &
  Omit<ContextMenuItemVariant, 'description'> & {
    description?: ReactNode;
    label?: ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
    shortcut?: string[];
  };

export const ContextMenuItem = ({
  children,
  className,
  description,
  label,
  prefix,
  shortcut,
  suffix,
  ...restOfProps
}: ContextMenuItemProps) => {
  const hasDescription = !!description;

  return (
    <RadixContextMenu.Item
      className={
        className || description
          ? contextMenuItem({ className, description: hasDescription })
          : CONTEXT_MENU_ITEM_BASE_CLASS_NAME
      }
      data-testid="CONTEXT_MENU_ITEM"
      {...restOfProps}
    >
      {!!children ? (
        children
      ) : (
        <>
          {!!prefix ? (
            <div className={classNames('mr-2 flex shrink-0 items-center', hasDescription ? 'mt-0.5' : 'mt-0')}>
              {prefix}
            </div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className="truncate text-14">{label}</span>
            {hasDescription ? <span className="text-12 text-grey-10">{description}</span> : null}
          </div>

          {!!suffix ? (
            <div className={classNames('ml-2 flex shrink-0 items-center', hasDescription ? 'mt-0.5' : 'mt-0')}>
              {suffix}
            </div>
          ) : null}
          {!!shortcut ? (
            <div className="ml-2 flex shrink-0 items-center">
              {shortcut.map((key, index) => (
                <kbd
                  className="mr-0.5 flex h-4 w-4 items-center justify-center rounded bg-grey-3 text-10 uppercase text-grey-8 last:mr-0"
                  key={index}
                >
                  {key}
                </kbd>
              ))}
            </div>
          ) : null}
        </>
      )}
    </RadixContextMenu.Item>
  );
};
