import { DialogOverlay, type DialogOverlayProps } from '@reach/dialog';
import { motion, Variants } from 'framer-motion';

const ACTION_SHEET_OVERLAY_MOTION_VARIANTS: Variants = {
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  initial: {
    opacity: 0,
  },
};

const MotionDialogOverlay = motion.custom(DialogOverlay);

export type ActionSheetOverlayProps = Pick<DialogOverlayProps, 'children'> & {
  onClose: () => void;
};

export const ActionSheetOverlay = ({ onClose, ...restOfProps }: ActionSheetOverlayProps) => {
  return (
    <MotionDialogOverlay
      animate="animate"
      className="fixed inset-0 bg-pigeon-900/60"
      exit="exit"
      initial="initial"
      onDismiss={onClose}
      transition={{
        type: 'tween',
        duration: 0.2,
      }}
      variants={ACTION_SHEET_OVERLAY_MOTION_VARIANTS}
      data-testid="ACTION_SHEET_OVERLAY"
      {...restOfProps}
    />
  );
};
