import { useCallback, useState } from 'react';

/**
 * @property {boolean} isHovering true if item is hovered
 * @property {() => void} handleHover method to call it when item is hovered
 * @property {() => void} handleUnhover method to call when item is no longer hovered
 */
export interface UseHoverType {
  isHovering: boolean;
  handleHover: () => void;
  handleUnhover: () => void;
}

/**
 * This hook is used to help handling hovering items on mouse enter/leave
 */
export function useHover(): UseHoverType {
  const [isHovering, setIsHovering] = useState(false);

  const handleHover = useCallback(() => {
    if (isHovering) {
      return;
    }

    setIsHovering(true);
  }, [isHovering, setIsHovering]);

  const handleUnhover = useCallback(() => {
    if (!isHovering) {
      return;
    }

    setIsHovering(false);
  }, [isHovering, setIsHovering]);

  return { handleUnhover, isHovering, handleHover };
}
