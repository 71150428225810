import { ActionSheet, ActionSheetHeader, ActionSheetOption, renderActionSheetItems } from '@air/primitive-action-sheet';
import { DropdownMenu, DropdownMenuOption, DropdownMenuProps, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { useBreakpointsContext } from '@air/provider-media-query';
import { useAirModal } from '@air/provider-modal';
import { noop } from 'lodash';
import Router from 'next/router';
import { cloneElement, type ReactElement, ReactNode, useEffect } from 'react';

/*
  this method can be used to manually hide menu dropdown
  useful e.g. when you have button in menu and you want to hide it after click
  it's hacky, but I didn't find any other way to hide it. It simulates mouseDown and mouseUp events on document body
  it can't be just 'click', since dropdown is hidden on mousedown event
  mouseup is required, because otherwise mouse scroll is fired
 */
export const manuallyHideDropdown = () => {
  const mouseDownEvt = new MouseEvent('mousedown', {
    view: window,
    bubbles: true,
  });

  const mouseUpEvt = new MouseEvent('mouseup', {
    view: window,
    bubbles: true,
  });

  document.body.dispatchEvent(mouseDownEvt);
  document.body.dispatchEvent(mouseUpEvt);
};

export type DropdownMenuActionSheetProps = {
  content?: ReactNode;
  isTitleHidden?: boolean;
  onChange?: (value: boolean) => void;
  options?: ActionSheetOption[];
  title?: string;
  container?: HTMLElement | null;
};

export const DropdownMenuActionSheet = ({
  content,
  isTitleHidden,
  onClose = noop,
  onChange = noop,
  title,
  options,
  ...restOfProps
}: Omit<AirModalProps<DropdownMenuActionSheetProps>, 'children'>) => {
  const { isAboveMediumScreen } = useBreakpointsContext();

  useEffect(() => {
    if (isAboveMediumScreen) {
      onClose();
    }
  }, [isAboveMediumScreen, onClose]);

  return (
    <ActionSheet
      {...restOfProps}
      onClose={() => {
        onClose();
        onChange?.(false);
      }}
    >
      {!isTitleHidden ? (
        <ActionSheetHeader
          onClose={() => {
            onClose();
            onChange?.(false);
          }}
        >
          {title}
        </ActionSheetHeader>
      ) : null}
      {content}
      {renderActionSheetItems({ options })}
    </ActionSheet>
  );
};

export type DropdownMenuWithActionSheetProps = Pick<DropdownMenuProps, 'size'> &
  Pick<DropdownMenuActionSheetProps, 'isTitleHidden' | 'onChange' | 'title'> & {
    children?: ReactNode;
    ['data-testid']?: string;
    options?: DropdownMenuOption[];
    trigger: ReactElement;
    container?: HTMLElement | null;
    'data-theme'?: 'dark' | 'light';
  };

/**
 * This component conditional renders ActionSheet and DropdownMenu based
 * on the current screen size and should be only be used for menus that
 * renders on mobile. Otherwise, use `DropdownMenu` from `@air/zephyr`.
 */
export const DropdownMenuWithActionSheet = ({
  children,
  ['data-testid']: dataTestId,
  isTitleHidden,
  onChange = noop,
  options,
  size,
  title,
  trigger,
  container,
  ['data-theme']: dataTheme,
}: DropdownMenuWithActionSheetProps) => {
  const [showActionSheet, close] = useAirModal(DropdownMenuActionSheet);
  const { isAboveMediumScreen } = useBreakpointsContext();

  useEffect(() => {
    const closeActionSheet = () => {
      close();
      onChange(false);
    };

    Router.events.on('routeChangeComplete', closeActionSheet);

    return () => Router.events.off('routeChangeComplete', closeActionSheet);
  }, [close, onChange]);

  return (
    <>
      {/*Action sheet does not work on fullscreen, and it requires more refactor to pass it there.
         This is a temporary solution to avoid breaking the app.
      */}
      {isAboveMediumScreen || container ? (
        <DropdownMenu
          container={container}
          data-testid={dataTestId}
          onOpenChange={onChange}
          size={size}
          trigger={trigger}
          data-theme={dataTheme}
        >
          {children}
          {renderDropdownItems({ options })}
        </DropdownMenu>
      ) : (
        cloneElement(trigger, {
          onClick: (event: MouseEvent) => {
            event.stopPropagation();
            showActionSheet({
              options: options?.map((option) => {
                if (option.type === 'item') {
                  return {
                    ...option,
                    onSelect: () => {
                      close();
                      option.onSelect?.();
                    },
                  };
                }

                return option;
              }),
              isTitleHidden,
              content: children,
              onChange,
              title,
            });
            onChange(true);
          },
        })
      )}
    </>
  );
};
