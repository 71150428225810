import { CustomFieldTypeName } from '@air/api/types';
import {
  Calendar as CalendarIcon,
  Field as FieldIcon,
  Fields as FieldsIcon,
  TextEdit as TextEditIcon,
} from '@air/next-icons';

export const customFieldIconMap: Record<CustomFieldTypeName, typeof FieldsIcon> = {
  [CustomFieldTypeName.singleSelect]: FieldIcon,
  [CustomFieldTypeName.multiSelect]: FieldsIcon,
  [CustomFieldTypeName.plainText]: TextEditIcon,
  [CustomFieldTypeName.date]: CalendarIcon,
};
