import { Color } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

import { CustomFieldValueFilterBase } from '~/components/Filters/CustomFieldFilter/utils';
import { ItemTypeFilter } from '~/components/Filters/types';
import { CustomFieldFilterType, FilterType } from '~/components/FiltersMenu/types';
import { TagBase } from '~/components/TagsSelect/utils';
import {
  DateRangeType,
  FilterLogicType,
  Filters,
  OtherFiltersType,
  SelectedFilter,
  StringArrayFilterType,
  UploaderFilterType,
} from '~/store/filters/types';
import { PersonWithDefaultFace } from '~/swr-hooks/person/types';

export const addFilterAction = createAction<{ filter: SelectedFilter }>('ADD_FILTER');
export const removeFiltersWithoutSelectionAction = createAction('REMOVE_FILTERS_WITHOUT_SELECTION');
export const removeFilterAction = createAction<{ filter: SelectedFilter }>('REMOVE_FILTER');
export const changeFilterTypeAction = createAction<{ oldFilter: SelectedFilter; newFilter: SelectedFilter }>(
  'CHANGE_FILTER_TYPE',
);
export const setSelectedFiltersAction = createAction<{ filters: SelectedFilter[] }>('SET_SELECTED_FILTERS');
export const removeAllFiltersAction = createAction('REMOVE_ALL_FILTERS');
export const removeFiltersAction = createAction<{ filters: FilterType[]; customFields: CustomFieldFilterType[] }>(
  'REMOVE_FILTERS',
);

export const setFiltersValuesAction = createAction<{ filters: Partial<Filters> }>('SET_FILTERS_VALUES_ACTION');

export const addItemTypeFilterAction = createAction<{ type: ItemTypeFilter }>('ADD_ITEM_TYPE_FILTER');
export const removeItemTypeFilterAction = createAction<{ type: ItemTypeFilter }>('REMOVE_ITEM_TYPE_FILTER');
export const clearItemTypeFiltersAction = createAction('CLEAR_ITEM_TYPE_FILTER');

export const addColorFilterValueAction = createAction<{ color: Color['hex'] }>('ADD_COLOR_FILTER');
export const removeColorFilterValueAction = createAction<{ color: Color['hex'] }>('REMOVE_COLOR_FILTER');
export const clearColorFilterValuesAction = createAction('CLEAR_COLOR_FILTER');

export const setTagsFilterLogicAction = createAction<{ logic: FilterLogicType }>('SET_TAGS_FILTER_LOGIC');
export const setTagsFiltersValueAction = createAction<{ tags: TagBase[] }>('SET_TAGS_FILTERS');
export const clearTagsFilterValuesAction = createAction('CLEAR_TAGS_FILTER');

export const addImportedKeywordsFilterValueAction = createAction<{ keyword: string }>('ADD_IMPORTED_KEYWORDS_FILTER');
export const removeImportedKeywordsFilterValueAction = createAction<{ keyword: string }>(
  'REMOVE_IMPORTED_KEYWORDS_FILTER',
);
export const setImportedKeywordsFilterLogicAction = createAction<{ logic: FilterLogicType }>(
  'SET_IMPORTED_KEYWORDS_LOGIC',
);
export const setImportedKeywordsFiltersValueAction = createAction<{ keywords: string[] }>(
  'SET_IMPORTED_KEYWORDS_FILTERS',
);

export const clearImportedKeywordsFilterValuesAction = createAction('CLEAR_IMPORTED_KEYWORDS_FILTER');

export const setUploadersFilterValuesAction = createAction<{ uploaders: UploaderFilterType[] }>(
  'SET_UPLOADERS_FILTERS',
);
export const clearUploadersFilterValuesAction = createAction('CLEAR_UPLOADERS_FILTER');

export const setCameraMakeFilterValuesAction = createAction<{ make: string }>('SET_CAMERA_MAKE_FILTERS');
export const setCameraModelsFilterValuesAction = createAction<{ models: string[] }>('SET_CAMERA_MODELS_FILTERS');
export const clearCamerasFilterValuesAction = createAction('CLEAR_CAMERAS_FILTER');

export const addOtherFilterValueAction = createAction<{ filter: OtherFiltersType }>('ADD_OTHERS_FILTER');
export const removeOtherFilterValueAction = createAction<{ filter: OtherFiltersType }>('REMOVE_OTHERS_FILTER');
export const setOtherFiltersValuesAction = createAction<{ filters: OtherFiltersType[] }>('SET_OTHERS_FILTERS');
export const clearOtherFiltersValuesAction = createAction('CLEAR_OTHERS_FILTER');

export const setCustomFieldFilterValuesAction = createAction<{
  customFieldId: CustomFieldFilterType['id'];
  values: CustomFieldValueFilterBase[];
}>('ADD_CUSTOM_FIELD_FILTER_VALUES');
export const setCustomFieldFilterLogicAction = createAction<{
  customFieldId: CustomFieldFilterType['id'];
  logic: FilterLogicType;
}>('SET_CUSTOM_FIELD_FILTER_LOGIC_TYPE');
export const clearCustomFieldFilterValuesAction = createAction<{ customFieldId: CustomFieldFilterType['id'] }>(
  'CLEAR_CUSTOM_FIELD_FILTER_VALUES_ACTION',
);

export const setCreatedDateFilterTypeAction = createAction<{ type: DateRangeType }>('SET_CREATED_DATE_FILTER_TYPE');
export const setCreatedDateFilterStartDateAction = createAction<{ startDate?: Date }>(
  'SET_CREATED_DATE_FILTER_START_DATE',
);
export const clearCreatedDateFilterValueAction = createAction('CLEAR_CREATED_DATE_FILTER_VALUE');
export const setCreatedDateFilterEndDateAction = createAction<{ endDate?: Date }>('SET_CREATED_DATE_FILTER_END_DATE');

export const setModifiedDateFilterTypeAction = createAction<{ type: DateRangeType }>('SET_MODIFIED_DATE_FILTER_TYPE');
export const setModifiedDateFilterStartDateAction = createAction<{ startDate?: Date }>(
  'SET_MODIFIED_DATE_FILTER_START_DATE',
);
export const clearModifiedDateFilterValueAction = createAction('CLEAR_MODIFIED_DATE_FILTER_VALUE');
export const setModifiedDateFilterEndDateAction = createAction<{ endDate?: Date }>('SET_MODIFIED_DATE_FILTER_END_DATE');

export const setUploadedDateFilterTypeAction = createAction<{ type: DateRangeType }>('SET_UPLOADED_DATE_FILTER_TYPE');
export const setUploadedDateFilterStartDateAction = createAction<{ startDate?: Date }>(
  'SET_UPLOADED_DATE_FILTER_START_DATE',
);
export const clearUploadedDateFilterValueAction = createAction('CLEAR_UPLOADED_DATE_FILTER_VALUE');
export const setUploadedDateFilterEndDateAction = createAction<{ endDate?: Date }>('SET_UPLOADED_DATE_FILTER_END_DATE');

export const setStringArrayFilterValuesAction = createAction<{ filter: StringArrayFilterType; values: string[] }>(
  'SET_STRING_ARRAY_FILTER_VALUES',
);
export const clearStringArrayFilterValuesAction = createAction<{ filter: StringArrayFilterType }>(
  'CLEAR_STRING_ARRAY_FILTER_VALUES',
);

export const setPeopleFilterLogicAction = createAction<{ logic: FilterLogicType }>('SET_PEOPLE_FILTER_LOGIC');
export const setPeopleFiltersValueAction = createAction<{ people: PersonWithDefaultFace[] }>('SET_PEOPLE_FILTERS');
export const clearPeopleFilterValuesAction = createAction('CLEAR_PEOPLE_FILTER');

export const setTakenDateFilterTypeAction = createAction<{ type: DateRangeType }>('SET_TAKEN_DATE_FILTER_TYPE');
export const setTakenDateFilterStartDateAction = createAction<{ startDate?: Date }>('SET_TAKEN_DATE_FILTER_START_DATE');
export const clearTakenDateFilterValueAction = createAction('CLEAR_TAKEN_DATE_FILTER_VALUE');
export const setTakenDateFilterEndDateAction = createAction<{ endDate?: Date }>('SET_TAKEN_DATE_FILTER_END_DATE');
