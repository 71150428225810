import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

export type SkeletonProps = Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style'> & {
  height?: number | string;
  width?: number | string;
  isText?: boolean;
  variant?: 'round' | 'square';
};

export const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({ className, height, isText, width, style, variant = 'square' }: SkeletonProps, ref) => {
    return (
      <div
        className={classNames('box-border', isText ? 'py-0.5' : undefined, className)}
        ref={ref}
        style={{
          width,
          height,
          ...style,
        }}
      >
        <div
          className={classNames('box-border h-full w-full animate-shimmer bg-grey-3 bg-skeleton', {
            'rounded-full': variant === 'round',
            'rounded-sm': variant === 'square',
          })}
          data-variant={variant}
          style={{
            animationFillMode: 'forwards',
            backgroundSize: '200px 100%',
            backgroundPositionX: '100%',
          }}
        />
      </div>
    );
  },
);

Skeleton.displayName = 'Skeleton';
