import { Upload } from '@air/redux-uploader';
import { createAction } from '@reduxjs/toolkit';

import { PrivateUploadMetadata } from '~/store/privateUploadMetadata/types';

interface PrivateUploadMetadataPayload {
  uploadId: Upload['id'];
  metadata: PrivateUploadMetadata;
}

export const setPrivateUploadMetadata = createAction<PrivateUploadMetadataPayload>('SET_PRIVATE_UPLOAD_METADATA');

export const setPrivateUploadsMetadata = createAction<{ uploadsMetadata: PrivateUploadMetadataPayload[] }>(
  'SET_PRIVATE_UPLOADS_METADATA',
);
