const styleMap: { color: string; backgroundColor: string }[] = [
  { color: 'var(--colors-pink12)', backgroundColor: 'var(--colors-red8)' },
  { color: 'var(--colors-red12)', backgroundColor: 'var(--colors-red3)' },
  { color: 'var(--colors-yellow12)', backgroundColor: 'var(--colors-yellow6)' },
  { color: 'var(--colors-yellow12)', backgroundColor: 'var(--colors-yellow3)' },
  { color: 'var(--colors-yellow12)', backgroundColor: 'var(--colors-orange5' },
  { color: 'var(--colors-yellow12)', backgroundColor: 'var(--colors-orange2)' },
  { color: 'var(--colors-teal12)', backgroundColor: 'var(--colors-green7)' },
  { color: 'var(--colors-teal12)', backgroundColor: 'var(--colors-green4)' },
  { color: 'var(--colors-blue11)', backgroundColor: 'var(--colors-teal7)' },
  { color: 'var(--colors-blue11)', backgroundColor: 'var(--colors-teal4)' },
  { color: 'var(--colors-grey1)', backgroundColor: 'var(--colors-indigo9)' },
  { color: 'var(--colors-blue11)', backgroundColor: 'var(--colors-indigo3' },
  { color: 'var(--colors-grey1)', backgroundColor: 'var(--colors-purple8)' },
  { color: 'var(--colors-purple12)', backgroundColor: 'var(--colors-purple4)' },
  { color: 'var(--colors-pink12)', backgroundColor: 'var(--colors-pink5)' },
  { color: 'var(--colors-pink11)', backgroundColor: 'var(--colors-pink2)' },
  { color: 'var(--colors-grey1)', backgroundColor: 'var(--colors-grey12)' },
  { color: 'var(--colors-grey11)', backgroundColor: 'var(--colors-grey6)' },
];

/**
 * Takes `colorSeed` (e.g. meant to be a user ID) and returns the same randomly chosen style from the list above consistently each time it gets called.
 * Borrowing logic from randomColor script by @davidmerfield
 * @see: https://github.com/davidmerfield/randomColor/blob/a045a83a2ee0de85ed5448b938ff4d6ef18a397e/randomColor.js#L291
 */
export const getAvatarRandomStyle = (colorSeed: string): { color: string; backgroundColor: string } => {
  const stringToInteger = () => {
    let total = 0;
    for (let i = 0; i !== colorSeed.length; i++) {
      if (total >= Number.MAX_SAFE_INTEGER) break;
      total += colorSeed.charCodeAt(i);
    }
    return total;
  };

  let seed = stringToInteger();

  /**
   * Seeded random algorithm from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
   */
  const randomWithin = () => {
    const max = styleMap.length - 1;
    const min = 0;
    seed = (seed * 9301 + 49297) % 233280;
    const rnd = seed / 233280;
    return Math.floor(min + rnd * (max - min));
  };

  return styleMap[randomWithin()];
};
