import { Input, InputProps } from '@air/primitive-input';
import { useField } from 'formik';
import { forwardRef } from 'react';

export type FormikInputProps = Required<Pick<InputProps, 'name'>> & Omit<InputProps, 'name'>;

export const FormikInput = forwardRef<HTMLInputElement, FormikInputProps>(({ name, ...restOfProps }, forwardedRef) => {
  const [field] = useField(name);

  return <Input {...field} {...restOfProps} ref={forwardedRef} />;
});

FormikInput.displayName = 'FormikInput';
