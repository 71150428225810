import { createReducer } from '@reduxjs/toolkit';

import { clearSelectedTagsAction, selectTagAction, unselectTagAction } from '~/store/selectedTags/actions';
import { SelectedTagsState } from '~/store/selectedTags/types';

const initialState: SelectedTagsState = {
  selectedTags: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(selectTagAction, (state, { payload: { tagId } }) => {
      state.selectedTags.push(tagId);
    })
    .addCase(unselectTagAction, (state, { payload: { tagId } }) => {
      state.selectedTags = state.selectedTags.filter((id) => tagId !== id);
    })
    .addCase(clearSelectedTagsAction, (state) => {
      state.selectedTags = [];
    });
});
