import { SortDirection, SortField } from '@air/api/types';

/** This is the cruz of all spacing and applies to all card types */
const KANBAN_CARD_WIDTH = 264;
/** Space between card and column edge, horizontally */
export const KANBAN_COL_PX = 12;
export const KANBAN_ITEM_GUTTER = 12;

/** Our cards have a strange offset that requires a special setting for the inner card width */
export const KANBAN_ITEM_CARD_WIDTH = KANBAN_CARD_WIDTH - 12;
/** Roughly the addage to the outside of the card */
export const KANBAN_ITEM_CARD_PADDING = 5;
/** Accounts for the cards adding border and padding */
export const KANBAN_CARD_LEFT_OFFSET = KANBAN_COL_PX + KANBAN_ITEM_CARD_PADDING;

export const KANBAN_ITEM_DROP_LINE_HEIGHT = 3;
export const KANBAN_ITEM_DROP_PLACEHOLDER_HEIGHT = KANBAN_ITEM_GUTTER + KANBAN_ITEM_DROP_LINE_HEIGHT;

/** We want the full asset card height before custom fields to be 240. */
export const KANBAN_ASSET_CARD_HEIGHT = 240 - KANBAN_ITEM_CARD_PADDING * 2;

/** We want the full board card height to be 176. */
export const KANBAN_BOARD_CARD_HEIGHT = 176 - KANBAN_ITEM_CARD_PADDING * 2;

/** We want the full file card height before custom fields to be 150. */
export const KANBAN_FILE_CARD_HEIGHT = 150 - KANBAN_ITEM_CARD_PADDING * 2;
export const KANBAN_VIRTUALIZED_CARD_GUTTER = KANBAN_ITEM_GUTTER + KANBAN_ITEM_CARD_PADDING * 2;
/** Just the visual column */
export const KANBAN_COL_WIDTH = KANBAN_CARD_WIDTH + KANBAN_COL_PX * 2; // 288
/** Spacing between each column, horizontally */
export const KANBAN_COL_GUTTER = 16;

// Spacing at the begining and end of the group of columns.
export const KANBAN_COL_LIST_SPACER = 32;

/** For virtualization, this includes half the gutter on either side of the visual column */
export const KANBAN_VIRTUALIZED_COL_WIDTH = KANBAN_COL_WIDTH + KANBAN_COL_GUTTER;

/** How many columns to be fetched at a time */
export const KANBAN_GROUP_FETCH_SIZE = 5;
export const KANBAN_COLUMN_FETCH_LIMIT = 100;

export const defaultKanbanSort: SortField = { name: 'customSort', direction: SortDirection.desc };
