import { Clip, ClipStatus, ClipType } from '@air/api/types';

import { Rotations } from '~/constants/Rotations';

import { videoFormat } from './VideoUtils';

type ClipStatusTypes = keyof typeof ClipStatus;

const TERMINAL_STATUSES = [ClipStatus.transcoded, ClipStatus.nonTranscodable];

export const getFirstNumberInId = (id: string): number => {
  const numbers = id.split('').filter((char) => !isNaN(parseInt(char)));
  return +(numbers[0] || '0');
};

export default class ClipUtils {
  static isTranscoded = (status?: ClipStatusTypes | null) => status === ClipStatus.transcoded;
  static isProcessing = (status?: ClipStatusTypes | null) =>
    status === ClipStatus.transcoding || status === ClipStatus.uploaded || status === ClipStatus.created;
  static isFailed = (status?: ClipStatusTypes | null) => status === ClipStatus.failed;
  static isNonTranscodable = (status?: ClipStatusTypes | null) => status === ClipStatus.nonTranscodable;
  static isTranscodeFinished = (status?: ClipStatus | null) =>
    (status && TERMINAL_STATUSES.includes(status)) || status === ClipStatus.failed;

  static canHavePreviewImage = (clipType: ClipType) => clipType !== ClipType.nonMedia && clipType !== ClipType.audio;

  static determinePlaybackRate = (duration: number, videoSrc: string) => {
    const fmt = videoFormat(videoSrc);
    if (fmt !== 'm3u8') return 1;

    if (duration < 15) return 2;
    if (duration < 30) return 3;
    if (duration < 60) return 4;
    return 5;
  };

  static isLandscape = (rotation: number) => {
    if (
      rotation === Rotations.landscape ||
      rotation === Rotations.otherLandscape ||
      rotation === Rotations.otherOtherLandscape
    ) {
      return true;
    }

    if (!(rotation === Rotations.portrait || rotation === Rotations.upsideDownPortrait)) {
      // console.warn('unexpected rotation metadata', rotation);
    }
    return false;
  };

  static changeVideoTimeInSeconds = (video: HTMLVideoElement, seconds: number) => {
    let newTime = video.currentTime + seconds;
    if (newTime > video.duration) newTime = video.duration;
    if (newTime < 0) newTime = 0;
    video.currentTime = newTime;
  };

  static changeVideoVolume = (video: HTMLVideoElement, amount: number) => {
    let newVolume = video.volume + amount;
    if (newVolume > 1) newVolume = 1;
    if (newVolume < 0) newVolume = 0;
    video.volume = newVolume;
  };
}

export const assetClipTypes = Object.keys(ClipType).filter((type) => type !== ClipType.nonMedia);
export const assetFileType = ClipType.nonMedia;

const getAssetIsFailed = (asset?: { status?: Clip['status'] | null }) => asset && ClipUtils.isFailed(asset.status);

const getAssetIsTranscoded = (asset?: { status?: Clip['status'] | null }) =>
  asset && ClipUtils.isTranscoded(asset.status);

const getAssetIsNonTranscodable = (asset?: { status?: Clip['status'] | null }) =>
  asset && ClipUtils.isNonTranscodable(asset.status);

export const getAssetIsProcessing = (asset?: { status?: Clip['status'] | null }) =>
  [getAssetIsFailed, getAssetIsTranscoded, getAssetIsNonTranscodable].reduce((isProcessing, check) => {
    return isProcessing && !check(asset);
  }, true);

export type WebClipType = 'pdf' | 'document' | 'photo' | 'video' | 'file';

export const getAssetPreviewType = ({
  type,
  ext,
}: Partial<Nullify<Pick<Clip, 'type' | 'ext'>, 'ext' | 'type'>>): WebClipType | undefined => {
  if (ext?.toLowerCase() === 'pdf') {
    return 'pdf';
  }

  if (type === ClipType.nonMedia) {
    return 'file';
  }

  return (['document', 'photo', 'video', 'file'] as WebClipType[]).find((t) => t === type);
};

const pdfRenderedExtensions = ['pdf', 'doc', 'docx', 'ppt', 'pptx', 'potx', 'key'];

export const isPdfRenderedExtension = (ext: string) => pdfRenderedExtensions.includes(ext.toLowerCase());

/**
 * a parameter that is ignored by imgix but used by the frontend
 * to clear the browser's cached thumbnails
 */
export const clearThumbnailCache = ({
  clipStatus,
  clipExt,
  clipUpdatedAt,
  imageSrc,
}: {
  clipStatus: Clip['status'];
  clipExt: Clip['ext'];
  clipUpdatedAt: Clip['updatedAt'];
  imageSrc: Clip['assets']['image'];
}) => {
  if (isPdfRenderedExtension(clipExt) && clipStatus === ClipStatus.transcoded && imageSrc) {
    return `${imageSrc}?updatedAt=${new Date(clipUpdatedAt).getTime()}`;
  }
  return imageSrc;
};

/**
 * Pass clip type and ext to check if clip can be annotated.
 * Clips that can not be annotated:
 * - audio
 * - nonMedia, unless it is {@link isPdfRenderedExtension}
 */
export const canClipBeAnnotated = ({ type, ext }: Pick<Clip, 'ext' | 'type'>) => {
  if (isPdfRenderedExtension(ext)) {
    return true;
  } else if (type === ClipType.nonMedia || type === ClipType.audio) {
    return false;
  }
  return true;
};
