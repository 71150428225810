import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import classNames from 'classnames';
import { ComponentPropsWithoutRef, memo } from 'react';

import { getAvatarRandomStyle } from '../utils/getAvatarRandomStyle';
import { getAvatarTextClassName } from '../utils/getAvatarTextClassName';

export const avatar = tailwindVariants({
  base: 'relative flex shrink-0 cursor-default items-center justify-center overflow-hidden bg-grey-3 object-cover text-grey-12',
  variants: {
    appearance: {
      circle: 'rounded-full',
      square: 'rounded',
    },
    disabled: {
      true: 'opacity-70 grayscale',
    },
  },
  defaultVariants: {
    appearance: 'circle',
    disabled: false,
  },
});

export type AvatarVariants = VariantProps<typeof avatar>;

export type AvatarSizes = 16 | 20 | 24 | 28 | 32 | 36 | 40 | 48 | 56 | 96;

export type AvatarProps = ComponentPropsWithoutRef<'div'> &
  AvatarVariants & {
    size?: AvatarSizes;
    text?: string;
    colorSeed?: string | null;
  } & (
    | {
        alt: string;
        src: string | null;
      }
    | {
        alt?: string;
        src?: string | null;
      }
  );

export const Avatar = memo(
  ({
    alt,
    appearance,
    size = 40,
    src,
    text,
    disabled = false,
    colorSeed,
    className,
    style,
    ...restOfProps
  }: AvatarProps) => {
    return (
      <div
        className={avatar({ appearance, disabled, class: className })}
        style={{
          ...(colorSeed ? getAvatarRandomStyle(colorSeed) : undefined),
          width: size,
          height: size,
          ...style,
        }}
        {...restOfProps}
      >
        {src ? (
          <img alt={alt} className="block object-cover" src={src} style={{ width: size, height: size }} />
        ) : text ? (
          <p className={classNames('font-bold', getAvatarTextClassName(size))}>{text}</p>
        ) : (
          <svg className="h-full w-full text-grey-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M16 19a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 26c6.627 0 12-5.373 12-12s-5.373-12-12-12S4 26.373 4 33s5.373 12 12 12Z"
            />
          </svg>
        )}
      </div>
    );
  },
);

Avatar.displayName = 'Avatar';
