import { Routes } from '~/constants/routes';

export const baseUrl = 'https://air-marketing-assets.imgix.net';
export const featuredImageUrl = `${baseUrl}/features`;
export const logoImageUrl = `${baseUrl}/logos`;
export const avatarImageUrl = `${baseUrl}/avatars`;
export const mastheadImageUrl = `${baseUrl}/mastheads`;
export const blogImageUrl = `${baseUrl}/blog`;

/** For use in window.open() or as a value of the `target` attribute on an anchor element. */
export const NEW_TAB = '_blank';

/** For use in window.open() or as a value of the `target` attribute on an anchor element. */
export const SAME_TAB = '_self';

export const EXPORT_IMAGES_AND_CDN_LINK_HELP_ARTICLE_URL = `${Routes.helpCenter}/en/articles/5563526-custom-downloads-and-cdn-links`;

export const SAVED_FILTERS_HELP_ARTICLE = `${Routes.helpCenter}/en/articles/6101236-saved-filters`;
