import { pausedUploadsSelector, setUploadsResumedAction, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useResumeAllUploads = () => {
  const dispatch = useDispatch();
  const store = useUploaderStore();

  const resumeAllUploads = useCallback(() => {
    const pausedUploads = pausedUploadsSelector(store.getState());
    dispatch(setUploadsResumedAction({ uploadIds: pausedUploads.map(({ id }) => id) }));
  }, [dispatch, store]);

  return {
    resumeAllUploads,
  };
};
