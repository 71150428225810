import { Close as CloseIcon } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import classNames from 'classnames';
import { ComponentProps } from 'react';

export type AlertModalCloseButtonProps = ComponentProps<'button'>;

export const AlertModalCloseButton = ({ className, ...restOfProps }: AlertModalCloseButtonProps) => {
  return (
    <button
      className={classNames(
        'flex h-6 w-6 items-center justify-center rounded border-0 bg-transparent text-grey-12 outline-none hover:bg-grey-4 focus-visible:ring-2 focus-visible:ring-blue-9 active:bg-grey-4',
        className,
      )}
      {...restOfProps}
    >
      <VisuallyHidden>Close modal</VisuallyHidden>
      <CloseIcon className="h-4 w-4" />
    </button>
  );
};
