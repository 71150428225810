import { Board, PublicBoard } from '@air/api/types';
import { hasSelectedItemsSelector, selectedItemsSelector } from '@air/redux-selected-items';
import { createSelector } from 'reselect';

import { isPrivateBoard } from '../centralizedBoard/utils';
import { SelectableGalleryAssetItem, SelectableGalleryBoardItem, SelectableGalleryFileItem } from './types';

/**
 * Returns selected items of type 'asset' or 'file'
 */
export const selectedClipItemsSelector = createSelector(selectedItemsSelector, (selected) =>
  selected.filter((item): item is SelectableGalleryAssetItem | SelectableGalleryFileItem =>
    ['asset', 'file'].includes(item.type),
  ),
);

/**
 * Returns selected clips (assets and files) from selected items
 */
export const selectedClipsSelector = createSelector(selectedClipItemsSelector, (selected) =>
  selected.map(({ item }) => item),
);

/**
 * Returns selected items of type 'asset'
 */
const selectedMediaClipItemsSelector = createSelector(selectedItemsSelector, (selected) =>
  selected.filter((item): item is SelectableGalleryAssetItem => item.type === 'asset'),
);

/**
 * Returns selected clips (assets) from selected items
 */
export const selectedMediaClipsSelector = createSelector([selectedMediaClipItemsSelector], (selected) =>
  selected.map(({ item }) => item),
);

/**
 * Returns selected items of type 'file'
 */
const selectedFileClipItemsSelector = createSelector(selectedItemsSelector, (selected) =>
  selected.filter((item): item is SelectableGalleryFileItem => item.type === 'file'),
);

/**
 * Returns selected clips (files) from selected items
 */
export const selectedFileClipsSelector = createSelector([selectedFileClipItemsSelector], (selected) =>
  selected.map(({ item }) => item),
);

export const selectedClipIdsSelector = createSelector(selectedClipItemsSelector, (selectedClips) =>
  selectedClips.map(({ id }) => id),
);

export const selectedClipsIdsInfoSelector = createSelector(selectedClipItemsSelector, (selectedClips) =>
  selectedClips.map(({ item: { id, assetId } }) => ({ id, assetId })),
);

export const selectedClipsAssetIdsSelector = createSelector(selectedClipItemsSelector, (selectedClips) =>
  selectedClips.map(({ item: { assetId } }) => assetId),
);

/**
 * Returns true if any asset or file is selected
 */
const clipsAreSelectedSelector = createSelector(selectedClipItemsSelector, (selectedClips) => selectedClips.length > 0);

/**
 * Returns true if at least one clip of type "asset" is selected
 */
const mediaClipsAreSelectedSelector = createSelector(
  selectedMediaClipItemsSelector,
  (selectedClips) => selectedClips.length > 0,
);

/**
 * Returns true if at least one clip of type "file" is selected
 */
const fileClipsAreSelectedSelector = createSelector(
  selectedFileClipItemsSelector,
  (selectedFiles) => selectedFiles.length > 0,
);

export const selectedBoardItemsSelector = createSelector(selectedItemsSelector, (selected) =>
  selected.filter((item): item is SelectableGalleryBoardItem<Board | PublicBoard> => item.type === 'board'),
);

export const selectedBoardPrivateItemsSelector = createSelector(selectedBoardItemsSelector, (selected) =>
  selected.filter((item): item is SelectableGalleryBoardItem<Board> => isPrivateBoard(item.item)),
);

export const selectedBoardsSelector = createSelector(selectedBoardItemsSelector, (selectedBoardItems) =>
  selectedBoardItems.map(({ item }) => item),
);
export const selectedPrivateBoardsSelector = createSelector(selectedBoardItemsSelector, (selectedBoardItems) =>
  selectedBoardItems.map(({ item }) => item).filter(isPrivateBoard),
);

export const selectedBoardsIdsSelector = createSelector(selectedBoardItemsSelector, (selectedBoards) =>
  selectedBoards.map(({ id }) => id),
);

const boardsAreSelectedSelector = createSelector(
  selectedBoardItemsSelector,
  (selectedBoards) => selectedBoards.length > 0,
);

/**
 * Returns true if any clip (image or file)is selected, but no boards
 */
export const hasOnlyClipsSelectedSelector = createSelector(
  [clipsAreSelectedSelector, boardsAreSelectedSelector],
  (clipsAreSelected, boardsAreSelected) => clipsAreSelected && !boardsAreSelected,
);

export const hasOnlyBoardsSelectedSelector = createSelector(
  [clipsAreSelectedSelector, boardsAreSelectedSelector],
  (clipsAreSelected, boardsAreSelected) => !clipsAreSelected && boardsAreSelected,
);

/**
 * Returns true if only clip of type "asset" is selected, but no files or boards
 */
const hasOnlyMediaClipsSelectedSelector = createSelector(
  [mediaClipsAreSelectedSelector, fileClipsAreSelectedSelector, boardsAreSelectedSelector],
  (mediaClipsAreSelected, filesAreSelected, boardsAreSelected) =>
    mediaClipsAreSelected && !filesAreSelected && !boardsAreSelected,
);

/**
 * Returns true if only clip of type "file" is selected, but no assets or boards
 */
const hasOnlyFileClipsSelectedSelector = createSelector(
  [mediaClipsAreSelectedSelector, fileClipsAreSelectedSelector, boardsAreSelectedSelector],
  (mediaClipsAreSelected, filesAreSelected, boardsAreSelected) =>
    !mediaClipsAreSelected && filesAreSelected && !boardsAreSelected,
);

export const selectedClipsHasUnfavorited = createSelector(selectedClipItemsSelector, (selected) =>
  selected.some(({ item }) => !item.bookmarked),
);

export const hasSelectedSameTypeItemsSelector = createSelector(
  hasSelectedItemsSelector,
  hasOnlyMediaClipsSelectedSelector,
  hasOnlyFileClipsSelectedSelector,
  hasOnlyBoardsSelectedSelector,
  (hasSelectedItems, hasOnlyClipsSelected, hasOnlyFilesSelected, hasOnlyBoardsSelected) =>
    !hasSelectedItems || hasOnlyClipsSelected || hasOnlyBoardsSelected || hasOnlyFilesSelected,
);
