import { Board, Clip, ClipAndBoardListOptions, SortByOption, Workspace } from '@air/api/types';
import { isNull, isUndefined, pickBy } from 'lodash';

import { ASSET_MODAL_DISCUSSION_FILTERS } from '~/components/AssetModal/shared/context/DiscussionsPanelContext/discussionsPanelContextTypes';

// STRING KEYS

export const BOARD_STATS = 'BOARD_STATS';
export const CUSTOM_FIELD_COLORS_LIST = 'CUSTOM_FIELD_COLORS_LIST';

export const DISCUSSIONS = 'DISCUSSIONS';

export const GALLERY_BOARDS = 'GALLERY_BOARDS';
export const SUBNAV_SORT_OPTIONS = 'SUBNAV_SORT_OPTIONS';
export const GALLERY_ASSETS = 'GALLERY_ASSETS';

const VERSIONS = `VERSIONS`;
const CUSTOM_FIELD_OPTIONS = 'CUSTOM_FIELD_OPTIONS';
export const BOARD_CUSTOM_FIELDS = 'BOARD_CUSTOM_FIELDS';
const PUBLIC_CUSTOM_FIELDS = 'PUBLIC_CUSTOM_FIELDS';
const WORKSPACE_CUSTOM_FIELDS = 'WORKSPACE_CUSTOM_FIELDS';
const CUSTOM_FIELD_TYPES_LIST = 'CUSTOM_FIELD_TYPES_LIST';

const CONFIG_VIEW_OPTIONS = 'CONFIG_VIEW_OPTIONS';
const KANBAN_INITIAL_ITEMS_BOARD = 'KANBAN_INITIAL_ITEMS_BOARD';

export const ASSETS_CUSTOM_FIELDS = 'ASSETS_CUSTOM_FIELDS';

const KANBAN_COLUMN_ITEMS = 'KANBAN_COLUMN_ITEMS';

const PRIVATE_BOARDS = 'PRIVATE_BOARDS';
const PAGE_BOARD = 'PAGE_BOARD';

export const SEARCH_TOTALS = 'SEARCH_TOTALS';
const PRIVATE_TAGS = 'PRIVATE_TAGS';

export const GALLERY_FILES = 'GALLERY_FILES';
export const GALLERY_MIXED_DATA = 'GALLERY_MIXED_DATA';
export const PRIVATE_MEDIA_PLAYER = 'PRIVATE_MEDIA_PLAYER';
const PUBLIC_MEDIA_PLAYER = 'PUBLIC_MEDIA_PLAYER';

/**
 * When passing params to react-query, it will
 * serialize undefined as a value. This means that the following params
 * will not be treated as matching in react-query:
 * { param: undefined } !== { }
 * This function will remove any keys that have undefined values from the param
 */

const cleanKeyParams = (params: object) => {
  return pickBy(params, (value) => !isUndefined(value));
};

// COMPLEX KEYS

export const getBaseBoardStatsKey = (boardId: string) => [BOARD_STATS, { boardId }];

export const getPrivateBoardStatsKey = (params: {
  includeDescendants: boolean;
  workspaceId?: Workspace['id'];
  boardId?: Board['id'];
}) => {
  return [BOARD_STATS, params];
};

export const getPublicBoardStatsKey = (params: {
  shortId: string;
  boardId: Board['id'];
  includeDescendants: boolean;
}) => {
  return [BOARD_STATS, params];
};

export const getVersionsListKey = (params: { assetId: Clip['assetId']; shortId?: string }) => {
  return [VERSIONS, params];
};

export const getCustomFieldOptionsKey = (customFieldId?: string) =>
  customFieldId ? [CUSTOM_FIELD_OPTIONS, { customFieldId }] : [CUSTOM_FIELD_OPTIONS];

export const getPrivateBoardCustomFieldsKey = (boardId?: Board['id']) => [BOARD_CUSTOM_FIELDS, { boardId }];

export const getPublicBoardCustomFieldsKey = (boardId?: Board['id'], shortId?: string) => [
  BOARD_CUSTOM_FIELDS,
  { boardId, shortId },
];

export const getPublicCustomFieldsKey = (shortId?: string) => {
  return [PUBLIC_CUSTOM_FIELDS, shortId ? { shortId } : undefined];
};

export const getWorkspaceCustomFieldsKey = (workspaceId?: string) =>
  workspaceId ? [WORKSPACE_CUSTOM_FIELDS, { workspaceId }] : [WORKSPACE_CUSTOM_FIELDS];

export const getCustomFieldTypesKey = (workspaceId: string | undefined) =>
  workspaceId ? [CUSTOM_FIELD_TYPES_LIST, { workspaceId }] : [CUSTOM_FIELD_TYPES_LIST];

export const getConfigViewOptionsKey = ({
  workspaceId,
  viewType,
  canSeeIPTCFields,
}: {
  workspaceId?: string;
  viewType?: string;
  canSeeIPTCFields: boolean;
}) => [CONFIG_VIEW_OPTIONS, { workspaceId, viewType, canSeeIPTCFields }];

//@TODO: double check this
export const getInitialKanbanItemsKey = (params: { boardId?: string; customFieldId?: string; viewId?: string }) => [
  KANBAN_INITIAL_ITEMS_BOARD,
  params,
];

//@TODO: needs work
export const getKanbanColumnItemsKey = (
  params: Partial<ClipAndBoardListOptions> & {
    boardId?: string;
    viewId?: string;
    customFieldId?: string;
    kanbanColumnId: string;
  },
) => [KANBAN_COLUMN_ITEMS, params];

export const getAssetCustomFieldsKey = (assetId: Clip['assetId'] | undefined) =>
  assetId ? [ASSETS_CUSTOM_FIELDS, { assetId }] : [ASSETS_CUSTOM_FIELDS];

export const getPrivateBoardKey = (boardId?: Board['id']) => [PRIVATE_BOARDS, { boardId }];

export const getPageBoardKey = (boardId?: Board['id'] | null) => [
  PAGE_BOARD,
  { boardId: isNull(boardId) ? undefined : boardId },
];

export const getPrivateSearchTotalKey = (params: { [key: string]: any }, workspaceId?: string) => [
  SEARCH_TOTALS,
  { ...params, workspaceId, isPrivate: true },
];

export const getPublicSearchTotalKey = (params: string, shortId?: string) => [
  SEARCH_TOTALS,
  { params, shortId, isPrivate: false },
];

interface GetPrivateTagsParams {
  workspaceId?: string;
  search?: string;
  sortBy?: SortByOption;
  isTagsSelect?: boolean;
}

export const getPrivateTagsKey = (params: GetPrivateTagsParams) => {
  return [PRIVATE_TAGS, params];
};

export const getPrivateMediaPlayerKey = (workspaceId?: string) => [PRIVATE_MEDIA_PLAYER, { workspaceId }];

export const getPublicMediaPlayerKey = (shortId: string) => [PUBLIC_MEDIA_PLAYER, { shortId }];

export interface GetDiscussionKeyParams {
  boardId?: Board['id'];
  assetId: Clip['assetId'];
  clipId?: Clip['id'];
  shortId?: string;
  discussionsFilter?: ASSET_MODAL_DISCUSSION_FILTERS;
}

export const getDiscussionsKey = (params: GetDiscussionKeyParams) => {
  const clipId = params.clipId;
  const boardId = params.boardId || 'noBoard';
  const shortId = params.shortId || 'noShortId';
  return [DISCUSSIONS, cleanKeyParams({ ...params, clipId, boardId, shortId }) as GetDiscussionKeyParams];
};
