import { Close } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { tailwindMerge } from '@air/tailwind-variants';
import { memo, MouseEvent } from 'react';

interface InputClearButtonProps {
  isVisible: boolean;
  isFlex?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
}

export const InputClearButton = memo(
  ({ isVisible, isFlex = false, onClick, 'data-testid': testId }: InputClearButtonProps) => {
    return (
      <div
        className={tailwindMerge(
          'flex h-full w-6 flex-col items-center justify-center',
          isFlex ? undefined : 'absolute right-1.5 top-0',
          isVisible ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
        )}
      >
        <IconButton
          label="Clear"
          data-testid={testId}
          appearance="ghost"
          color="grey"
          icon={Close}
          size="small"
          onClick={onClick}
        />
      </div>
    );
  },
);

InputClearButton.displayName = 'InputClearButton';
