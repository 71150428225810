import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { memo } from 'react';

export const dropdownMenuLabel = tailwindVariants({
  base: 'text-12 font-bold uppercase tracking-wide text-grey-10',
  variants: {
    size: {
      small: 'px-1.5',
      large: 'px-2',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type DropdownMenuLabelVariants = VariantProps<typeof dropdownMenuLabel>;

export type DropdownMenuLabelProps = Omit<RadixDropdownMenu.DropdownMenuLabelProps, 'size'> & DropdownMenuLabelVariants;

export const DropdownMenuLabel = memo(({ className, size = 'small', ...restOfProps }: DropdownMenuLabelProps) => {
  return (
    <RadixDropdownMenu.Label
      className={dropdownMenuLabel({ size, className })}
      data-testid="DROPDOWN_MENU_LABEL"
      {...restOfProps}
    />
  );
});

DropdownMenuLabel.displayName = 'DropdownMenuLabel';
