import { NetworkStatusInfo } from '@air/classes-network-status-info';
import { useEffect } from 'react';

export const useConnectionStatusListener = (onConnectionChange: (isConnected: boolean) => void) => {
  useEffect(() => {
    /**
     * When an online event fires on the window,
     * the user may have connected to wifi but may not actually have internet access.
     * so, we need to check if they are actually online.
     * @see https://stackoverflow.com/a/4813406
     */
    const useOnlineCallback = () => {
      NetworkStatusInfo.checkIfOnline();
    };
    window.addEventListener('online', useOnlineCallback);

    /**
     * When an offline event happens on the window,
     * we can be sure the user is offline, so we can call setIsOffline safely
     */
    const useOfflineCallback = () => {
      onConnectionChange(false);
      NetworkStatusInfo.setIsOffline();
    };
    window.addEventListener('offline', useOfflineCallback);

    return () => {
      window.removeEventListener('online', useOnlineCallback);
      window.removeEventListener('offline', useOfflineCallback);
    };
  }, [onConnectionChange]);

  useEffect(() => {
    const { unsubscribe } = NetworkStatusInfo.subscribe(onConnectionChange);

    return () => {
      unsubscribe();
    };
  }, [onConnectionChange]);
};
