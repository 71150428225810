import { isString } from 'lodash';
import { useCallback } from 'react';

export enum InviteType {
  email = 'email',
  link = 'link',
}

export interface JoinInfo {
  id: string;
  name: string;
  image: string;
  email?: string;
  shortId: string;
  inviteType: InviteType;
}

export const useJoinWorkspaceInfo = () => {
  const key = `join-workspace-info`;

  const getJoinWorkspaceInfo = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const item = sessionStorage.getItem(key);

    return isString(item) ? (JSON.parse(item) as JoinInfo) : undefined;
  }, [key]);

  const setJoinWorkspaceInfo = useCallback(
    (info: JoinInfo) => {
      if (typeof window === 'undefined') {
        return;
      }

      sessionStorage.setItem(key, JSON.stringify(info));
    },
    [key],
  );

  const clearJoinWorkspaceInfo = useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }

    sessionStorage.removeItem(key);
  }, [key]);

  return {
    getJoinWorkspaceInfo,
    setJoinWorkspaceInfo,
    clearJoinWorkspaceInfo,
  };
};
