import { Board, Clip, ClipAndBoardListItem } from '@air/api/types';

export const getSortableKanbanItemKey = (item: ClipAndBoardListItem) => {
  switch (item.type) {
    case 'asset':
      return `kanban-drag-asset-${(item.data as Clip).id}`;
    case 'file':
      return `kanban-drag-file-${(item.data as Clip).id}`;
    case 'board':
      return `kanban-drag-board-${(item.data as Board).id}`;
  }
};

export const getSortableKanbanColumnKey = (kanbanColumnId: string) => `kanban-sortable-column-${kanbanColumnId}`;
export const getDroppableKanbanColumnKey = (kanbanColumnId: string) => `kanban-droppable-column-${kanbanColumnId}`;
export const getSortableContextKanbanColumnItemsKey = (kanbanColumnId: string) =>
  `kanban-sortable-context-${kanbanColumnId}`;
export const sortableContextKanbanColumnOrderKey = 'dnd-sortable-context-kanban-column-order';
