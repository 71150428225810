import { Box, BoxProps } from '@air/zephyr';
import { ElementType, memo, ReactNode } from 'react';

export interface PaneItemProps extends Pick<BoxProps, 'tx'> {
  as?: ElementType;
  title: ReactNode;
  subtitle?: ReactNode;
  avatar: ReactNode;
  buttons: ReactNode;
  previewLoader?: ReactNode;
  onClick?: () => void;
}

export const PaneItem = memo(
  ({ as = 'div', title, subtitle, avatar, buttons, tx, previewLoader, onClick }: PaneItemProps) => (
    <Box
      onClick={onClick}
      as={as}
      tx={{ display: 'flex', m: 0, listStyle: 'none', justifyContent: 'space-between', ...tx }}
    >
      {previewLoader}
      <Box tx={{ display: 'flex', flexShrink: 1 }}>
        {avatar}

        <Box
          tx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            flex: 1,
            '& > *': {
              display: 'block',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            },
          }}
        >
          {title}
          {subtitle}
        </Box>
      </Box>

      <Box
        className="pane-item-buttons"
        tx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexShrink: 0,
          ml: 10,
          '& button:not(:last-of-type)': {
            mr: 4,
          },
        }}
      >
        {buttons}
      </Box>
    </Box>
  ),
);

PaneItem.displayName = 'PaneItem';
