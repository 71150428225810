import { ClipType, CreateMultipartResponse, FileUpload, UploadUrlResponse } from '@air/api/types';

export interface InputFile extends File {
  readonly path?: string;
}

export enum UploadStatus {
  /** This state is when urls are being fetched for an upload but the bytes for that actual file haven't started uploading yet */
  preparing = 'preparing',
  completed = 'completed',
  failed = 'failed',
  aborted = 'aborted',
  rejected = 'rejected',
  uploading = 'uploading',
  queued = 'queued',
  paused = 'paused',
}

interface UploadData extends FileUpload {
  type: ClipType;
}

export interface RejectedUpload {
  id: string;
  name: string;
  date: number;
  message: string;
  reason: string;
  status: UploadStatus.rejected;
}

interface UploadSubBase {
  clipId: string;
  s3Info: FileS3Info;
}

export interface UploadBase extends UploadSubBase {
  id: string;
  batchId: string;
  file: FileData['file'];
  apiUploadData: FileData['apiUploadData'];
  createdAt: number;
  status: UploadStatus;
  imageBase64?: string;
  isImageLoading?: boolean;
  isNonMedia: boolean;
  completedAt?: number;
}

export interface LargeUpload extends Omit<UploadBase, 's3Info'> {
  xhr?: { [partNumber: number]: XMLHttpRequest };
  s3Info: Omit<FileS3Info, 'uploadUrlInfo'> & {
    uploadUrlInfo?: CreateMultipartResponse[0];
  };
}

export interface SmallUpload extends Omit<UploadBase, 's3Info'> {
  xhr?: XMLHttpRequest;
  s3Info: Omit<FileS3Info, 'uploadUrlInfo'> & {
    uploadUrlInfo?: UploadUrlResponse[0];
  };
}

export type Upload = LargeUpload | SmallUpload;

export interface FileData {
  custom: UploadSubBase;
  apiUploadData: UploadData;
  file: InputFile;
}

export interface FileS3Info {
  uploadUrlInfo?: CreateMultipartResponse[0] | UploadUrlResponse[0];
  totalBytesUploaded?: number;
  uploadedParts?: { ETag: string; PartNumber: number }[];
}

export interface UploadProgressInfo {
  progress: number;
  progressBeforePause: number;
  abort?: () => void;
  partsProgress?: { [partNumber: number]: number };
}

export interface UploaderState {
  uploadsArray: Upload[];
  rejectedUploads: RejectedUpload[];
  uploadProgress: { [uploadId: string]: UploadProgressInfo };
  currentUploadingInfo: {
    startedAt: number;
    uploadedBytes: number;
    /**
     * If set, this number represents the number of bytes/second the user's upload speed is currently at
     */
    speed: number | null;
  };
}

export const initialUploadProgress: UploadProgressInfo = {
  progress: 0,
  progressBeforePause: 0,
};

export interface UploadPart {
  ETag: string;
  PartNumber: number;
}

export type UploaderStore = { uploader: UploaderState };

export type LargeUploadWithS3 = Omit<LargeUpload, 's3Info'> & {
  s3Info: Omit<LargeUpload['s3Info'], 'uploadUrlInfo'> & Required<Pick<LargeUpload['s3Info'], 'uploadUrlInfo'>>;
};
