import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { memo } from 'react';

const BASE_CLASS = 'text-12 font-bold uppercase tracking-wide text-grey-10 px-1.5';

export type ContextMenuLabelProps = RadixContextMenu.ContextMenuLabelProps;

export const ContextMenuLabel = memo(({ className, ...restOfProps }: ContextMenuLabelProps) => {
  return (
    <RadixContextMenu.Label
      className={className ? tailwindMerge(BASE_CLASS, className) : BASE_CLASS}
      data-testid="CONTEXT_MENU_LABEL"
      {...restOfProps}
    />
  );
});

ContextMenuLabel.displayName = 'ContextMenuLabel';
