import { AnyAction, CombinedState, configureStore, ConfigureStoreOptions, EnhancedStore } from '@reduxjs/toolkit';
import { createRouterMiddleware } from 'connected-next-router';
import LogRocket from 'logrocket';

import createRootReducer from './rootReducer';
import { AirState } from './types';

let globalStore: null | {
  store: EnhancedStore<CombinedState<AirState>, AnyAction, any[]>;
} = null;

export const makeStore = (preloadedState?: ConfigureStoreOptions<AirState>['preloadedState']) => {
  let _globalStore: typeof globalStore = globalStore
    ? globalStore
    : {
        store: configureStore({
          reducer: createRootReducer(),
          middleware: [createRouterMiddleware(), LogRocket.reduxMiddleware()],
          preloadedState,
        }),
      };

  if (preloadedState && globalStore) {
    const existingState = globalStore.store.getState() as ConfigureStoreOptions<AirState>['preloadedState'];
    _globalStore = {
      store: configureStore({
        reducer: createRootReducer(),
        middleware: [createRouterMiddleware(), LogRocket.reduxMiddleware()],
        preloadedState: {
          ...existingState,
          ...preloadedState,
        },
      }),
    };
    globalStore = null;
  }

  if (typeof window === 'undefined') return _globalStore;

  if (!globalStore) globalStore = _globalStore;

  return _globalStore;
};
