import * as ReachAlertDialog from '@reach/alert-dialog';
import classNames from 'classnames';
import { ComponentProps } from 'react';

export type AlertModalTitleProps = ReachAlertDialog.AlertDialogLabelProps & Omit<ComponentProps<'div'>, 'ref'>;

export const AlertModalTitle = ({ className, ...restOfProps }: AlertModalTitleProps) => {
  return (
    <ReachAlertDialog.AlertDialogLabel
      className={classNames('text-24 font-semibold text-grey-12', className)}
      {...restOfProps}
    />
  );
};
