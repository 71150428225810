import { resetUploadsAction } from '@air/redux-uploader';
import { createReducer } from '@reduxjs/toolkit';

import { addPathToBoardIdAction, setCreatingUploadBoardsAction } from '~/store/uploadingPathToBoardId/actions';
import { UploadingPathToBoardIdState } from '~/store/uploadingPathToBoardId/types';

const initialState: UploadingPathToBoardIdState = {};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(resetUploadsAction, () => ({}))

    .addCase(setCreatingUploadBoardsAction, (state, action) => {
      const { paths, pathRootId } = action.payload;
      /**
       * Filter out any paths that are already in the state to avoid overwriting
       */
      const newPaths = paths.filter((path) => !state[pathRootId]?.[path]);

      newPaths.forEach((path) => {
        const currentPathToBoard = state[pathRootId] || {};

        state[pathRootId] = {
          ...currentPathToBoard,
          [path]: {
            ...(currentPathToBoard[path] || {}),
            isCreating: true,
          },
        };
      });
    })
    .addCase(addPathToBoardIdAction, (state, action) => {
      const { path, boardId, pathRootId } = action.payload;
      const currentPathToBoard = state[pathRootId] || {};
      state[pathRootId] = {
        ...currentPathToBoard,
        [path]: {
          ...(currentPathToBoard[path] || {}),
          isCreating: false,
          boardId,
        },
      };
    });
});
