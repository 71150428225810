import { useCopyToWorkspace } from '~/components/CopyToWorkspace/hooks/useCopyToWorkspace';
import { ShowCustomFieldBulkEditModalParams } from '~/hooks/customFields/useShowPublicCustomFieldBulkEditModal';
import { useCreateComment } from '~/swr-hooks/discussions/useCreateComment';
import { useSubmitNewDiscussion } from '~/swr-hooks/discussions/useSubmitNewDiscussion';
import { UpdateItemsCustomFieldValueParams } from '~/utils/CustomFields/useUpdateItemsCustomFieldValue';

const prefix = 'ssoCallback:';
const urlKey = `${prefix}:url`;
const dataKey = `${prefix}:data`;

type SSOCallbackActionsNames =
  | 'copy-to-air'
  | 'oauth-redirect'
  | 'create-public-discussion'
  | 'reply-to-public-comment'
  | 'bulk-edit-custom-field-value'
  | 'edit-custom-field-value';

interface SSOCallbackActionBase<T> {
  action: SSOCallbackActionsNames;
  data?: T;
}

type ReplyToPublicCommentData = Parameters<ReturnType<typeof useCreateComment>['createComment']>[0];

interface SSOReplyToPublicCommentAction extends SSOCallbackActionBase<ReplyToPublicCommentData> {
  action: 'reply-to-public-comment';
}

type CopyToAirData = Parameters<ReturnType<typeof useCopyToWorkspace>['copyToAir']>[0];

interface SSOCopyToAirAction extends SSOCallbackActionBase<CopyToAirData> {
  action: 'copy-to-air';
}

type SubmitNewPublicDiscussionData = Parameters<ReturnType<typeof useSubmitNewDiscussion>['submitNewDiscussion']>[0];

interface SSOCreatePublicDiscussionAction extends SSOCallbackActionBase<SubmitNewPublicDiscussionData> {
  action: 'create-public-discussion';
}

export interface SSOBulkEditCustomFieldValueAction extends SSOCallbackActionBase<ShowCustomFieldBulkEditModalParams> {
  action: 'bulk-edit-custom-field-value';
}

export interface SSOEditCustomFieldValueAction extends SSOCallbackActionBase<UpdateItemsCustomFieldValueParams> {
  action: 'edit-custom-field-value';
}

interface SSORedirectAction {
  action: 'oauth-redirect';
}

export type SSOCallbackAction =
  | SSOCopyToAirAction
  | SSORedirectAction
  | SSOCreatePublicDiscussionAction
  | SSOReplyToPublicCommentAction
  | SSOBulkEditCustomFieldValueAction
  | SSOEditCustomFieldValueAction;

export interface SSOCallbackData {
  url: string;
  action?: SSOCallbackAction;
}

export const saveSSOCallbackData = ({ action, url }: SSOCallbackData) => {
  sessionStorage.setItem(urlKey, url);
  if (action) {
    sessionStorage.setItem(dataKey, JSON.stringify(action));
  }
};

export const clearSSOCallbackData = () => {
  sessionStorage.removeItem(urlKey);
  sessionStorage.removeItem(dataKey);
};

export const getSSORedirectUrl = () => sessionStorage.getItem(urlKey);

export const getSSOCallbackData = (): SSOCallbackAction | null => {
  const data = sessionStorage.getItem(dataKey);

  if (data) {
    return JSON.parse(data);
  }
  return null;
};
