import {
  AlertModal,
  AlertModalCloseButton,
  AlertModalDescription,
  AlertModalProps,
  AlertModalTitle,
} from '@air/primitive-alert-modal';
import { Button, ButtonProps } from '@air/primitive-button';
import { ReactNode, useRef } from 'react';

export type ConfirmationModalProps = Omit<AlertModalProps, 'children' | 'id'> &
  Partial<Pick<AlertModalProps, 'children'>> & {
    ctaPrimaryText?: string;
    ctaSecondaryText?: string;
    description?: ReactNode;
    id: string;
    /**
     * If the confirmation is dangerous, it will be styled as a red button.
     */
    isDangerous?: boolean;
    /**
     * This sets the primary button to a disabled state.
     */
    isDisabled?: boolean;
    /**
     * This sets the primary button to a loading state. While in this state, the primary and secondary button will be disabled.
     */
    isLoading?: boolean;
    onConfirm?: ButtonProps['onClick'];
    title: ReactNode;
  };

export const ConfirmationModal = ({
  ctaPrimaryText = 'Confirm',
  ctaSecondaryText = 'Cancel',
  description,
  children,
  id,
  isDangerous,
  isDisabled,
  isLoading,
  onDismiss,
  onConfirm,
  title,
  ...restOfProps
}: ConfirmationModalProps) => {
  const leastDestructiveRef = useRef(null);

  return (
    <AlertModal
      data-id={id}
      data-testid="CONFIRMATION_MODAL"
      leastDestructiveRef={leastDestructiveRef}
      onDismiss={onDismiss}
      {...restOfProps}
    >
      <div className="mb-3 flex justify-between">
        <AlertModalTitle data-testid="CONFIRMATION_MODAL_TITLE">{title}</AlertModalTitle>
        <AlertModalCloseButton data-testid="CONFIRMATION_MODAL_CLOSE" className="shrink-0" onClick={onDismiss} />
      </div>
      {!!description && <AlertModalDescription className="pb-2 pt-1">{description}</AlertModalDescription>}

      {children}

      <div className="-mx-6 -mb-6 flex justify-end gap-3 px-6 py-4">
        <Button
          appearance="ghost"
          color="grey"
          data-testid="CONFIRMATION_MODAL_SECONDARY_CTA"
          disabled={isLoading}
          onClick={onDismiss}
          ref={leastDestructiveRef}
          size="large"
        >
          {ctaSecondaryText}
        </Button>
        <Button
          appearance="filled"
          color={isDangerous ? 'red' : 'blue'}
          data-testid="CONFIRMATION_MODAL_PRIMARY_CTA"
          disabled={isDisabled}
          isLoading={isLoading}
          onClick={onConfirm}
          size="large"
        >
          {ctaPrimaryText}
        </Button>
      </div>
    </AlertModal>
  );
};
