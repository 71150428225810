import { pauseableUploadsSelector, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';

import { usePauseUploads } from './usePauseUploads';

export const usePauseAllUploads = () => {
  const store = useUploaderStore();
  const { pauseUploads } = usePauseUploads();

  const pauseAllUploads = useCallback(() => {
    const activeUploads = pauseableUploadsSelector(store.getState());
    pauseUploads(activeUploads);
  }, [pauseUploads, store]);

  return {
    pauseAllUploads,
  };
};
