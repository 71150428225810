import type * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Fragment, type ReactNode } from 'react';

import { type DropdownMenuVariant } from '../DropdownMenu';
import { DropdownMenuItem } from '../DropdownMenuItem';
import { DropdownMenuLabel } from '../DropdownMenuLabel';
import { DropdownMenuRadioGroup, type DropdownMenuRadioGroupProps } from '../DropdownMenuRadioGroup';
import { DropdownMenuRadioItem } from '../DropdownMenuRadioItem';
import { DropdownMenuSeparator } from '../DropdownMenuSeparator';
import { DropdownMenuSub } from '../DropdownMenuSub';

export type DropdownMenuOptionBase = {
  id: string;
};

export type DropdownMenuOptionChildren = DropdownMenuOptionBase & {
  children: ReactNode;
  type: 'children';
};

export type DropdownMenuOptionRadioGroup = DropdownMenuOptionBase &
  Pick<DropdownMenuRadioGroupProps, 'onValueChange' | 'value'> & {
    options?: DropdownMenuOptionRadioItem[];
    type: 'radio-group';
  };

export type DropdownMenuOptionRadioItem = DropdownMenuOptionBase &
  Omit<DropdownMenuOptionItem, 'suffix' | 'type'> & {
    type: 'radio-item';
    value: string;
  };

export type DropdownMenuOptionSeparator = DropdownMenuOptionBase & {
  className?: string;
  type: 'separator';
};

export type DropdownMenuOptionTitle = DropdownMenuOptionBase & {
  className?: string;
  label: string;
  type: 'title';
};

export type DropdownMenuOptionSub = Omit<DropdownMenuOptionItem, 'type'> & {
  options: DropdownMenuOption[];
  type: 'sub';
};

export type DropdownMenuOption =
  | DropdownMenuOptionChildren
  | DropdownMenuOptionItem
  | DropdownMenuOptionRadioItem
  | DropdownMenuOptionRadioGroup
  | DropdownMenuOptionSeparator
  | DropdownMenuOptionTitle
  | DropdownMenuOptionSub;

export type DropdownMenuOptionItem = DropdownMenuOptionBase &
  Pick<RadixDropdownMenu.MenuItemProps, 'disabled'> & {
    className?: string;
    description?: string;
    label: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    shortcut?: string[];
    suffix?: ReactNode;
    type: 'item';
  };

export type RenderDropdownItemsParams = {
  options?: DropdownMenuOption[];
  size?: DropdownMenuVariant['size'];
};

export const renderDropdownItems = ({ options, size = 'small' }: RenderDropdownItemsParams) => {
  if (!options) return null;

  return options.map((option, index) => {
    switch (option.type) {
      case 'children':
        return <Fragment key={option.id || index}>{option.children}</Fragment>;
      case 'item':
        return (
          <DropdownMenuItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            onSelect={option.onSelect}
            prefix={option.prefix}
            shortcut={option.shortcut}
            size={size}
            suffix={option.suffix}
            key={option.id || index}
          />
        );
      case 'radio-group':
        return (
          <DropdownMenuRadioGroup key={option.id} onValueChange={option.onValueChange} value={option.value}>
            {renderDropdownItems({
              options: option.options,
              size,
            })}
          </DropdownMenuRadioGroup>
        );
      case 'radio-item':
        return (
          <DropdownMenuRadioItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            key={option.id || index}
            onSelect={option.onSelect}
            prefix={option.prefix}
            size={size}
            value={option.value}
          />
        );
      case 'separator':
        return <DropdownMenuSeparator className={option.className} data-id={option.id} key={option.id || index} />;
      case 'sub':
        return (
          <DropdownMenuSub
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            prefix={option.prefix}
            size={size}
            suffix={option.suffix}
            key={option.id || index}
          >
            {renderDropdownItems({
              options: option.options,
              size,
            })}
          </DropdownMenuSub>
        );
      case 'title':
        return (
          <DropdownMenuLabel className={option.className} data-id={option.id} key={option.id || index} size={size}>
            {option.label}
          </DropdownMenuLabel>
        );
      default:
        return null;
    }
  });
};
