/**
 * @description Given an email, this will return that emails domain
 * @param email the complete email string (i.e. `bob@air.inc`)
 * @returns the email's domain url (i.e. `air.inc`)
 */
export const getEmailDomain = (email: string) => email.replace(/.*@/, '');

/**
 * @description Determines, using `.match` and a regex, whether or not a given string is a valid email domain
 * @param email email to validate
 * @returns boolean indicating whether or not the email has a valid email domain
 */
export const isValidEmailDomain = (email: string) =>
  !!email.match(
    // Source: https://stackoverflow.com/a/20046959/557531
    /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/,
  );

export const isValidEmailAddress = (email: string) => {
  if (!email) return false;

  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return emailRegex.test(email.toLowerCase());
};

/**
 * @description This function takes an email and trims and lowercases it
 * @param email the email that needs to be trimmed and lowercased
 * @returns sanitized email that's lowercased and trimmed
 */
export const sanitizeEmail = (email?: string) => (email ? email.trim().toLowerCase() : '');
