import { ReactNode } from 'react';
import BaseLinkify from 'react-linkify';

import { NEW_TAB } from '~/constants/urls';
/**
 *
 * This component will parse its `children`, detect URL's and wrap them in an `a` tag.
 * Otherwise it renders whatever it was passed as `children`.
 */
const Linkify = ({ children }: { children: ReactNode }) => {
  return (
    <BaseLinkify
      componentDecorator={(href: string, linkText: string, key: number) => (
        <a
          key={key}
          href={href}
          target={NEW_TAB}
          rel="noopener noreferrer"
          onClick={(e) => {
            // we have to stop propagation in order for the `Description`'s `Button` onClick
            // handler not to trigger
            e.stopPropagation();
          }}
          onFocus={(e) => {
            // we have to stop propagation in order for the `Description`'s `Button` onClick
            // handler not to trigger
            e.stopPropagation();
          }}
        >
          {linkText}
        </a>
      )}
    >
      {children}
    </BaseLinkify>
  );
};

export default Linkify;
