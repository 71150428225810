import { tailwindMerge } from '@air/tailwind-variants';
import { Box, Button, Text, TextProps, TXProp } from '@air/zephyr';
import { useId } from '@reach/auto-id';
import classNames from 'classnames';
import { Form, Formik, FormikConfig } from 'formik';
import { noop } from 'lodash';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import * as Yup from 'yup';

import { EditableTextTextarea } from './EditableTextTextarea';

export const EDITABLE_TEXT_FIELD_NAME = 'editable-text-value';

export const EditableTextSchema = Yup.object().shape({
  [EDITABLE_TEXT_FIELD_NAME]: Yup.string().trim(),
});

export const RequiredEditableTextSchema = Yup.object().shape({
  [EDITABLE_TEXT_FIELD_NAME]: Yup.string().trim().required('This field is required'),
});

export const NoLinebreakEditableTextSchema = Yup.object().shape({
  [EDITABLE_TEXT_FIELD_NAME]: Yup.string()
    .trim()
    .matches(/^[^\n]*$/, 'No line breaks allowed'),
});

export const RequiredNoLinebreakEditableTextSchema = Yup.object().shape({
  [EDITABLE_TEXT_FIELD_NAME]: Yup.string()
    .trim()
    .matches(/^[^\n]*$/, 'No line breaks allowed')
    .required('This field is required'),
});

export type EditableTextFormValues = {
  [EDITABLE_TEXT_FIELD_NAME]: string;
};

export type EditableTextProps = Pick<TextProps, 'as' | 'variant'> &
  Pick<FormikConfig<EditableTextFormValues>, 'onSubmit'> & {
    'data-testid'?: string;
    behavior?: 'box' | 'text';
    disabled?: boolean;
    errorClassName?: string;
    formatValue?: (children: ReactNode) => ReactNode;
    id?: string;
    isEditing?: boolean;
    isSingleLine?: boolean;
    label: string;
    /**
     * This will set the max character length for the textarea.
     */
    maxLength?: number;
    onEditingStateChange?: (isEditingState: boolean) => void;
    onReset?: () => void;
    placeholder?: string;
    readOnly?: boolean;
    tx?: TXProp & {
      EditableTextButton?: TXProp;
      EditableTextText?: TXProp;
      EditableTextTextarea?: TXProp;
    };
    value: string;
    error?: string;
    onValueChange?: (value: string) => void;
    required?: boolean;
    validationSchema?: any | (() => any);
  };

export const EditableText = ({
  as,
  behavior = 'box',
  ['data-testid']: testId,
  disabled,
  errorClassName,
  formatValue = (value) => value,
  isEditing = false,
  isSingleLine,
  id,
  label,
  maxLength,
  onEditingStateChange = noop,
  onReset = noop,
  onSubmit = noop,
  placeholder,
  readOnly,
  tx = {},
  value = '',
  variant = 'text-ui-16',
  error,
  onValueChange,
  required,
  validationSchema,
}: EditableTextProps) => {
  const autoId = useId(id)!;
  const [isEditingState, setIsEditingState] = useState(isEditing);
  const isPreviousIsEditing = usePrevious(isEditingState);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isTextBehavior = behavior === 'text';
  const {
    EditableTextButton: buttonStyles,
    EditableTextText: textStyles,
    EditableTextTextarea: textareaStyles,
    ...containerStyles
  } = tx;

  const setIsEditing = useCallback(
    (isEditing: boolean) => {
      setIsEditingState(isEditing);
      onEditingStateChange(isEditing);
    },
    [onEditingStateChange],
  );

  useEffect(() => {
    if (isEditingState && textareaRef?.current) {
      textareaRef.current?.focus();
      textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);
    }

    if (!isEditingState && isPreviousIsEditing && buttonRef?.current) {
      buttonRef.current.focus();
    }
  }, [buttonRef, isEditingState, isPreviousIsEditing, textareaRef]);

  const computedSchema =
    validationSchema ||
    (required
      ? isSingleLine
        ? RequiredNoLinebreakEditableTextSchema
        : RequiredEditableTextSchema
      : isSingleLine
      ? NoLinebreakEditableTextSchema
      : EditableTextSchema);

  return (
    <Formik<EditableTextFormValues>
      enableReinitialize
      onReset={() => {
        onReset();
        setIsEditing(false);
      }}
      validateOnChange={false}
      initialValues={{ [EDITABLE_TEXT_FIELD_NAME]: value }}
      onSubmit={async (values, formikHelpers) => {
        /**
         * Only submit the form if the value has changed.
         */
        const formikError = await formikHelpers.validateForm();
        const fieldError = formikError[EDITABLE_TEXT_FIELD_NAME];
        const rawValue = values[EDITABLE_TEXT_FIELD_NAME];
        const trimmedValue = rawValue.trim();
        const trimmedValues: EditableTextFormValues = {
          [EDITABLE_TEXT_FIELD_NAME]: trimmedValue,
        };
        formikHelpers.setFieldValue(EDITABLE_TEXT_FIELD_NAME, trimmedValue);
        if (!fieldError) {
          setIsEditing(false);
          onSubmit(trimmedValues, formikHelpers);
        }
      }}
      validationSchema={computedSchema}
    >
      {({ values, errors }) => {
        const formError = error ?? errors[EDITABLE_TEXT_FIELD_NAME];
        return (
          <Box
            data-testid={testId}
            tx={{
              position: 'relative',
              display: 'inline-flex',
              verticalAlign: 'text-top',
              textAlign: 'left',
              ...containerStyles,
            }}
          >
            <div
              className={classNames(
                'flex grow rounded px-2 py-1.5',
                disabled ? 'cursor-not-allowed' : readOnly ? 'cursor-default' : 'cursor-pointer',
                isTextBehavior ? '-mx-2 -my-1.5' : 'mx-0 my-0',
                isEditingState ? 'ring-2 ring-inset' : 'ring-0',
                !!formError ? 'ring-red-9' : 'ring-blue-9',
              )}
            >
              <Text
                as={as}
                tx={{
                  display: 'flex',
                  position: 'relative',
                  flexGrow: 1,
                  color: 'var(--colors-grey11)',
                  ...textStyles,
                }}
                variant={variant}
              >
                <Button
                  data-testid="EDITABLE_TEXT_BUTTON"
                  disabled={disabled ?? readOnly}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  ref={buttonRef}
                  tabIndex={isEditingState ? -1 : undefined}
                  tx={{
                    alignItems: 'flex-start',
                    flexGrow: 1,
                    justifyContent: 'flex-start',
                    mx: -8,
                    my: -6,
                    px: 8,
                    py: 6,
                    borderRadius: 4,
                    color: values[EDITABLE_TEXT_FIELD_NAME] ? 'inherit' : 'var(--colors-grey11)',
                    fontFamily: 'inherit',
                    fontFeatureSettings: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: values[EDITABLE_TEXT_FIELD_NAME] ? 'inherit' : 'regular',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                    textAlign: 'inherit',
                    whiteSpace: 'pre-wrap',
                    opacity: isEditingState && values[EDITABLE_TEXT_FIELD_NAME] !== '' ? 0 : 1,

                    '&:hover': {
                      backgroundColor: isEditingState ? 'transparent' : 'var(--colors-grey4)',
                      color: values[EDITABLE_TEXT_FIELD_NAME] !== '' ? 'inherit' : 'var(--colors-grey11)',
                    },

                    '&:active': {
                      backgroundColor: isEditingState ? 'transparent' : 'var(--colors-grey4)',
                      color: values[EDITABLE_TEXT_FIELD_NAME] !== '' ? 'inherit' : 'var(--colors-grey11)',
                    },

                    '&:focus-visible': {
                      backgroundColor: 'pigeon050',
                      boxShadow: 'none',
                    },

                    '&:focus': {
                      backgroundColor: isEditingState ? 'transparent' : 'var(--colors-grey4)',
                    },

                    '&:disabled': {
                      color: readOnly
                        ? values[EDITABLE_TEXT_FIELD_NAME] !== ''
                          ? 'inherit'
                          : 'var(--colors-grey11)'
                        : 'var(--colors-grey11)',
                    },

                    /**
                     * @todo I will deal with this at a later date, already spent 1+ hours on this.
                     */
                    ...(buttonStyles as any),
                  }}
                  variant="button-unstyled"
                >
                  {formatValue(values[EDITABLE_TEXT_FIELD_NAME] || placeholder)}
                  {/**
                   * This is need to ensure that newlines will always show the proper spacing
                   * when using the CSS property `white-space` with the value of `pre-wrap`.
                   */}
                  {values[EDITABLE_TEXT_FIELD_NAME].includes('\n') && <>&nbsp;</>}
                </Button>

                {isEditingState && (
                  <Form>
                    <EditableTextTextarea
                      id={autoId}
                      isSingleLine={isSingleLine}
                      label={label}
                      maxLength={maxLength}
                      name="editable-text-value"
                      onValueChange={onValueChange}
                      ref={textareaRef}
                      required
                      tx={textareaStyles}
                    />
                  </Form>
                )}
              </Text>
            </div>
            {isEditingState && !!formError && (
              <div
                className={tailwindMerge(
                  'absolute -bottom-1.5 right-4 bg-grey-1 px-2 py-0.5 text-10 font-medium text-flamingo-600',
                  errorClassName,
                )}
              >
                {formError}
              </div>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};
