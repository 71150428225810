export type ExponentialBackoffParams = {
  /* The number of attempts that have been made */
  attempt: number;
  /* The initial delay value to use */
  initialDelay: number;
  /* The max delay value  */
  maxDelay: number;
  /* How quickly should the time being attempts grow */
  factor: number;
};

export const getExponentialBackoff = ({
  attempt,
  maxDelay,
  initialDelay,
  factor,
}: ExponentialBackoffParams): number => {
  // Calculate the exponential backoff delay
  const delay = Math.min(initialDelay * Math.pow(factor, attempt - 1), maxDelay);

  // Add a random jitter to spread out the retries
  const jitter = Math.random() * 0.3 * delay;

  // Calculate the total delay, making sure it does not exceed maxDelay
  const totalDelay = Math.min(Math.floor(delay + jitter), maxDelay);

  // Return the total delay in milliseconds
  return totalDelay;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export type CallWithRetryParams<T> = {
  /** The function you want to try and call. It's return value gets returned from callWithRetry */
  action: () => Promise<T>;
  /**
   * This function expects a boolean to be returned and tells callWithRetry whether or not to retry the function you passed.
   * This function is passed the number of retries and the error so you can determine if you want to retry it or not.
   */
  shouldRetry: (retries: number, error: unknown) => boolean | Promise<boolean>;
  /** This function is called before the shouldRetry function and is called whenever the action you passed throws.
   * This function is called regardless of whether or not shouldRetry returns true/false
   * */
  onError?: (error: unknown) => void;
  /**
   * This function returns a number representing the the delay between the next retry (in milliseconds).
   * This is where you should put in some exponential backoff logic.
   */
  getRetryDelay?: (retries: number) => number;
};

export const callWithRetry = <T>({
  action,
  shouldRetry,
  onError,
  getRetryDelay = () => 5000,
}: CallWithRetryParams<T>) => {
  let retries = 0;

  const callAction = async (): Promise<T | undefined> => {
    try {
      return await action();
    } catch (error) {
      onError?.(error);

      const retry = await shouldRetry(retries, error);

      if (retry) {
        retries += 1;
        await sleep(getRetryDelay(retries));
        return callAction();
      }
    }
  };

  return callAction();
};
