import { failedUploadsSelector, setUploadsResumedAction, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useRetryAllUploads = () => {
  const dispatch = useDispatch();
  const store = useUploaderStore();

  const retryAllUploads = useCallback(() => {
    const failedUploads = failedUploadsSelector(store.getState());
    dispatch(setUploadsResumedAction({ uploadIds: failedUploads.map(({ id }) => id) }));
  }, [dispatch, store]);

  return {
    retryAllUploads,
  };
};
