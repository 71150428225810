import { config } from '@air/api';

import {
  configureAPIPackage,
  isApiConfiguredForFullAccountSignUp,
  restoreAPIPackageConfiguration,
} from '~/utils/ConfigureAmplify';

/**
 * This method configures cognito to use full account user pool and calls callback using this configuration
 * If callback throws an error, it restores original configuration
 * If callback succeeds, full user pool is set as current config
 *
 * @deprecated Use `callOnFullAccountUserPool` from `@air/utils-auth` instead.
 */
export const callOnFullAccountUserPool = async <T>(callback: () => Promise<T> | T) => {
  // if API is already configured as full account, call callback
  if (isApiConfiguredForFullAccountSignUp()) {
    return callback();
  }

  // else, configure API as anonymous, call callback and restore original configuration
  const savedAPIConfig = config.getAuthConfiguration();
  try {
    configureAPIPackage();
    return await callback();
  } catch (error) {
    if (savedAPIConfig) {
      restoreAPIPackageConfiguration(savedAPIConfig);
    }
    throw error;
  }
};
