import { capitalize, startCase } from 'lodash';

export const getConfigFieldLabel = (name: string) => {
  switch (name) {
    case 'name':
      return 'Name';
    case 'dateCreated':
      return 'Date created';
    case 'dateModified':
      return 'Date modified';
    case 'dateTaken':
      return 'Date taken';
    case 'ext':
      return 'Extension';
    case 'size':
      return 'Size';
    case 'titleAndMetadata':
      return 'Asset details';
    case 'type':
      return 'Type';
    case 'lastModified':
      return 'Date modified';
    case 'newest':
      return 'Date created';
    case 'oldest':
      return 'Date created';
    case 'aToZ':
      return 'Name';
    case 'zToA':
      return 'Name';
    case 'customSort':
      return 'Custom sort';
    case 'tagCount':
      return 'Tag count';
    case 'boardCount':
      return 'Board count';
    case 'openCommentCount':
      return 'Comment count';
    case 'resolution':
      return 'Resolution';
    case 'firstName':
      return 'First name';
    case 'lastName':
      return 'Last name';
    default:
      return capitalize(startCase(name));
  }
};
