import { memo } from 'react';

interface SelectEmptyOptionProps {
  noOptionsText?: string;
}

export const SelectEmptyOption = memo(({ noOptionsText }: SelectEmptyOptionProps) => {
  return (
    <li className="flex w-full items-center justify-center">
      <div className="text-12 font-medium text-grey-10">{noOptionsText || 'No options'}</div>
    </li>
  );
});

SelectEmptyOption.displayName = 'SelectEmptyOption';
