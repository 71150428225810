import { pauseableUploadsSelector, Upload, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';

import { usePauseUploads } from './usePauseUploads';

export const usePauseUploadById = () => {
  const store = useUploaderStore();
  const { pauseUploads } = usePauseUploads();

  const pauseUploadById = useCallback(
    async (uploadId: Upload['id']) => {
      const uploads = pauseableUploadsSelector(store.getState());
      const upload = uploads.find(({ id }) => id === uploadId);
      if (upload) {
        pauseUploads([upload]);
      }
    },
    [pauseUploads, store],
  );

  return {
    pauseUploadById,
  };
};
