import { Check } from '@air/next-icons';
import { tailwindVariants, type VariantProps } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { type ReactNode } from 'react';

import { dropdownMenuItem } from './DropdownMenuItem';

export const dropdownMenuRadioItem = tailwindVariants({
  extend: dropdownMenuItem,
  base: 'justify-between',
});

export type DropdownMenuRadioItemVariant = VariantProps<typeof dropdownMenuRadioItem>;

export type DropdownMenuRadioItemProps = Omit<RadixDropdownMenu.DropdownMenuRadioItemProps, 'prefix'> &
  DropdownMenuRadioItemVariant & {
    description?: ReactNode;
    label?: ReactNode;
    prefix?: ReactNode;
  };

export const DropdownMenuRadioItem = ({
  children,
  className,
  description,
  label,
  prefix,
  size,
  ...restOfProps
}: DropdownMenuRadioItemProps) => {
  const hasDescription = !!description;
  const isSmall = size === 'small';

  return (
    <RadixDropdownMenu.RadioItem className={dropdownMenuRadioItem({ size, className })} {...restOfProps}>
      {!!children ? (
        children
      ) : (
        <>
          {!!prefix ? (
            <div className={classNames('mr-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}>
              {prefix}
            </div>
          ) : null}

          <div className="flex min-w-0 grow flex-col">
            <span className={classNames('truncate', isSmall ? 'text-14' : 'text-16')}>{label}</span>
            {hasDescription ? (
              <span className={classNames('text-grey-8', isSmall ? 'text-12' : 'text-14')}>{description}</span>
            ) : null}
          </div>
        </>
      )}

      <RadixDropdownMenu.ItemIndicator
        className={classNames('ml-2 shrink-0', hasDescription ? (isSmall ? 'mt-0.5' : 'mt-1') : 'mt-0')}
      >
        <Check className="h-4 w-4" />
      </RadixDropdownMenu.ItemIndicator>
    </RadixDropdownMenu.RadioItem>
  );
};
