import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { memo } from 'react';

export const DROPDOWN_MENU_SEPARATOR_BASE_CLASS_NAME = 'h-px bg-grey-4 -mx-2 shrink-0';

export type DropdownMenuSeparatorProps = RadixDropdownMenu.DropdownMenuSeparatorProps;

export const DropdownMenuSeparator = memo(({ className, ...restOfProps }: DropdownMenuSeparatorProps) => {
  return (
    <RadixDropdownMenu.Separator
      className={
        className
          ? tailwindMerge(DROPDOWN_MENU_SEPARATOR_BASE_CLASS_NAME, className)
          : DROPDOWN_MENU_SEPARATOR_BASE_CLASS_NAME
      }
      data-testid="DROPDOWN_MENU_SEPARATOR"
      {...restOfProps}
    />
  );
});

DropdownMenuSeparator.displayName = 'DropdownMenuSeparator';
