import { Clip } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

import { LargeUpload, RejectedUpload, SmallUpload, Upload, UploadPart } from './types';

/*
  Upload logic is described here
  @see https://www.notion.so/airinc/Web-Uploader-71b92ab060d3476883fe9fb1a9774323
 */

export const setUploadCompletedAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_COMPLETED');
export const setUploadPreparingAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_PREPARING');
export const setUploadUploadingAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_UPLOADING');
export const setUploadsQueuedAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOADS_QUEUED');
export const setUploadAbortedAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_ABORTED');
export const setUploadFailedAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_FAILED');
export const queueUploadsAction = createAction<{ uploads: Upload[] }>('QUEUE_UPLOADS');
export const removeUploadsAction = createAction<{ uploadIds: Upload['id'][] }>('REMOVE_UPLOADS');
export const setUploadSkippedAction = createAction<{ uploadId: Upload['id'] }>('SET_UPLOAD_SKIPPED');
export const removeRejectedUploadAction = createAction<{ rejectedUpload: RejectedUpload }>('REMOVE_REJECTED_UPLOAD');
export const setUploadImagePreviewAction = createAction<{ uploadId: Upload['id']; imageBase64: string }>(
  'SET_UPLOAD_PREVIEW_IMAGE',
);
export const setAllFilesUploaded = createAction('SET_ALL_FILES_UPLOADED');
export const setUploadImageLoadingAction = createAction<{ uploadId: Upload['id']; isImageLoading: boolean }>(
  'SET_UPLOAD_IMAGE_LOADING',
);
export const setUploadsPausedAction = createAction<{ uploadIds: Upload['id'][] }>('SET_UPLOAD_PAUSED');
export const setUploadsResumedAction = createAction<{ uploadIds: Upload['id'][] }>('SET_UPLOAD_RESUMED');
export const setSmallUploadCancellablesAction = createAction<{
  uploadId: SmallUpload['id'];
  xhr: NonNullable<SmallUpload['xhr']>;
  uploadUrlInfo: SmallUpload['s3Info']['uploadUrlInfo'];
}>('SET_SMALL_UPLOAD_CANCELLABLES');

export const setLargeUploadCancellablesAction = createAction<{
  uploadId: LargeUpload['id'];
  xhr: NonNullable<LargeUpload['xhr']>;
  uploadUrlInfo: LargeUpload['s3Info']['uploadUrlInfo'];
}>('SET_LARGE_UPLOAD_CANCELLABLES');

export const setLargeUploadUrlInfoAction = createAction<{
  uploadId: LargeUpload['id'];
  uploadUrlInfo: LargeUpload['s3Info']['uploadUrlInfo'];
}>('SET_LARGE_UPLOAD_URL_INFO');

export const setUploadedPartInfo = createAction<{
  uploadId: Upload['id'];
  totalBytesUploaded?: number;
  uploadedPart: UploadPart;
}>('SET_UPLOADED_PART_INFO');

export const setUploadingSpeedAction = createAction<{ speed: number | null }>('SET_UPLOADING_SPEED');

export const resetUploadsAction = createAction('RESET_UPLOADS_ACTION');

export const rejectUploadsAction = createAction<{ files: { name: string; reason: string }[] }>('REJECT_UPLOADS');

export const setSmallUploadProgressAction = createAction<{
  uploadId: string;
  progress: number;
  abort?: () => void;
}>('SET_SMALL_UPLOAD_PROGRESS');

export const setLargeUploadProgressAction = createAction<{
  uploadId: string;
  progress: number;
  abort?: () => void;
  partNumber: number;
}>('SET_LARGE_UPLOAD_PROGRESS');

export const removeUploadsForClipsAction = createAction<{ clipIds: Clip['id'][] }>('REMOVE_UPLOAD_FOR_CLIP');
