import { Board, PublicBoard, User } from '@air/api/types';
import { createAction } from '@reduxjs/toolkit';

import { BoardPageBoard } from './types';

export const setCentralizedBoardIdAndTitleAction = createAction<{
  board: Pick<BoardPageBoard, 'id' | 'title'>;
}>('SET_CENTRALIZED_BOARD_ID_TITLE');

export const setCentralizedBoardAction = createAction<{ board: Board }>('SET_CENTRALIZED_BOARD');

export const setCentralizedPublicBoardAction = createAction<{ board: PublicBoard }>('SET_CENTRALIZED_PUBLIC_BOARD');

export const resetCentralizedBoardAction = createAction('RESET_CENTRALIZED_BOARD');

export const removeUserFromBoardAction = createAction<{ userId: User['id']; boardId: BoardPageBoard['id'] }>(
  'REMOVE_USER_FROM_BOARD',
);

interface AddUserToBoardPayload {
  user: Pick<User, 'id' | 'avatar' | 'firstName' | 'lastName' | 'email'>;
  boardId: BoardPageBoard['id'];
  moveToTop: boolean;
}

export const addUserToBoardAction = createAction<AddUserToBoardPayload>('ADD_USER_TO_BOARD');

export const editBoardSuccessAction = createAction<{
  board: Pick<BoardPageBoard, 'id'> & Partial<Pick<Board, 'description' | 'title'>>;
}>('EDITED_BOARD_SUCCESS');

export const moveBoardRequestAction = createAction<{
  /** The boards you want to move */
  boards: Board[];
  /** The id of the board you want to move the boards to */
  newParentId: Board['id'] | null;
  /** The id of the boards they were removed from */
  oldParentId: Board['id'] | null;
}>('MOVE_BOARDS_REQUEST');
