import { GetMultipartPartResponse } from '@air/api/types';
import {
  LargeUploadWithS3,
  setLargeUploadCancellablesAction,
  setLargeUploadProgressAction,
  setUploadedPartInfo,
  setUploadUploadingAction,
  Upload,
  uploadedPartsSelector,
  uploadProgressSelector,
  UploadStatus,
  useUploaderStore,
} from '@air/redux-uploader';
import { OnProgressParameter, uploadFile } from '@air/upload';
import { isUndefined } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export interface UploadFileChunkParams {
  upload: LargeUploadWithS3;
  partNumber: number;
  chunk: Blob;
  getPartUrl: (partNumber: number) => Promise<GetMultipartPartResponse>;
  onPartError?: (params: { upload: Upload; partNumber: number; error: unknown }) => void;
}

export const useUploadLargeFileChunk = () => {
  const store = useUploaderStore();
  const dispatch = useDispatch();

  const uploadLargeFileChunk = useCallback(
    async ({ chunk, upload, partNumber, getPartUrl }: UploadFileChunkParams) => {
      if (upload.status !== UploadStatus.uploading) {
        dispatch(setUploadUploadingAction({ uploadId: upload.id }));
      }

      const uploadedParts = uploadedPartsSelector(store.getState(), upload.id);

      if (uploadedParts.some(({ PartNumber }) => PartNumber === partNumber)) return;

      const {
        s3Info,
        s3Info: {
          uploadUrlInfo: { mime },
        },
      } = upload;

      const { url } = await getPartUrl(partNumber);

      const _onProgress = ({ progress, abort }: OnProgressParameter) => {
        if (!isUndefined(progress)) {
          dispatch(setLargeUploadProgressAction({ uploadId: upload.id, progress, abort, partNumber }));
        }
      };

      const onUploadStart = (xhr: XMLHttpRequest) => {
        console.info('starting upload for partNumber:', partNumber);
        dispatch(
          setLargeUploadCancellablesAction({
            xhr: { [partNumber]: xhr },
            uploadId: upload.id,
            uploadUrlInfo: s3Info.uploadUrlInfo,
          }),
        );
      };

      const { ETag } = await uploadFile({
        file: chunk as File,
        url,
        contentType: mime,
        onProgress: _onProgress,
        onStart: onUploadStart,
        returnTag: true,
      });

      console.group('Completed upload');
      console.info('ETag', ETag);
      console.info('partNumber', partNumber);
      console.groupEnd();

      if (!ETag) {
        throw new Error('Missing ETag for upload');
      }

      const uploadedPart = { ETag, PartNumber: partNumber };

      const { progress: totalUploaded } = uploadProgressSelector(store.getState(), upload.id);

      dispatch(
        setUploadedPartInfo({
          uploadId: upload.id,
          totalBytesUploaded: totalUploaded,
          uploadedPart,
        }),
      );
    },
    [dispatch, store],
  );

  return {
    uploadLargeFileChunk,
  };
};
