import type * as RadixContextMenu from '@radix-ui/react-context-menu';
import { ContextMenuRadioGroupProps } from '@radix-ui/react-context-menu';
import { Fragment, type ReactNode } from 'react';

import { ContextMenuItem } from '../components/ContextMenuItem';
import { ContextMenuLabel } from '../components/ContextMenuLabel';
import { ContextMenuRadioGroup } from '../components/ContextMenuRadioGroup';
import { ContextMenuRadioItem } from '../components/ContextMenuRadioItem';
import { ContextMenuSeparator } from '../components/ContextMenuSeparator';
import { ContextMenuSub } from '../components/ContextMenuSub';

export type ContextMenuOptionBase = {
  id: string;
};

export type ContextMenuOptionChildren = ContextMenuOptionBase & {
  children: ReactNode;
  type: 'children';
};

export type ContextMenuOptionSeparator = ContextMenuOptionBase & {
  className?: string;
  type: 'separator';
};

export type ContextMenuOptionTitle = ContextMenuOptionBase & {
  className?: string;
  label: string;
  type: 'title';
};

export type ContextMenuOptionSub = Omit<ContextMenuOptionItem, 'type'> & {
  options: ContextMenuOption[];
  type: 'sub';
};

export type ContextMenuOptionRadioGroup = ContextMenuOptionBase &
  Pick<ContextMenuRadioGroupProps, 'onValueChange' | 'value'> & {
    options?: ContextMenuOptionRadioItem[];
    type: 'radio-group';
  };

export type ContextMenuOptionRadioItem = ContextMenuOptionBase &
  Omit<ContextMenuOptionItem, 'suffix' | 'type'> & {
    type: 'radio-item';
    value: string;
  };

export type ContextMenuOption =
  | ContextMenuOptionChildren
  | ContextMenuOptionItem
  | ContextMenuOptionRadioItem
  | ContextMenuOptionRadioGroup
  | ContextMenuOptionSeparator
  | ContextMenuOptionTitle
  | ContextMenuOptionSub;

export type ContextMenuOptionItem = ContextMenuOptionBase &
  Pick<RadixContextMenu.ContextMenuItemProps, 'disabled'> & {
    className?: string;
    description?: string;
    label: ReactNode;
    onSelect?: () => void;
    prefix?: ReactNode;
    shortcut?: string[];
    suffix?: ReactNode;
    type: 'item';
  };

export type RenderContextItemsParams = {
  options?: ContextMenuOption[];
};

export const renderContextMenuItems = ({ options }: RenderContextItemsParams) => {
  if (!options) return null;

  return options.map((option, index) => {
    switch (option.type) {
      case 'children':
        return <Fragment key={option.id || index}>{option.children}</Fragment>;
      case 'item':
        return (
          <ContextMenuItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            onSelect={option.onSelect}
            prefix={option.prefix}
            shortcut={option.shortcut}
            suffix={option.suffix}
            key={option.id || index}
          />
        );
      case 'separator':
        return <ContextMenuSeparator className={option.className} data-id={option.id} key={option.id || index} />;
      case 'sub':
        return (
          <ContextMenuSub
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            prefix={option.prefix}
            suffix={option.suffix}
            key={option.id || index}
          >
            {renderContextMenuItems({
              options: option.options,
            })}
          </ContextMenuSub>
        );
      case 'radio-group':
        return (
          <ContextMenuRadioGroup key={option.id} onValueChange={option.onValueChange} value={option.value}>
            {renderContextMenuItems({ options: option.options })}
          </ContextMenuRadioGroup>
        );
      case 'radio-item':
        return (
          <ContextMenuRadioItem
            className={option.className}
            data-id={option.id}
            description={option.description}
            disabled={option.disabled}
            label={option.label}
            key={option.id || index}
            onSelect={option.onSelect}
            prefix={option.prefix}
            value={option.value}
          />
        );
      case 'title':
        return (
          <ContextMenuLabel className={option.className} data-id={option.id} key={option.id || index}>
            {option.label}
          </ContextMenuLabel>
        );
      default:
        return null;
    }
  });
};
