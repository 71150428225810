import { getClipTypeFromExt, getExt } from '@air/api/shared-utils';
import { ClipType } from '@air/api/types';
import { CHUNK_SIZE, FileData, isLarge, LargeUpload, SmallUpload, Upload, UploadStatus } from '@air/redux-uploader';
import { abortUpload as abortUploadRequest, USER_ABORTED_UPLOAD } from '@air/upload';
import { uniqueId } from 'lodash';
import { basename, extname } from 'path';

export const getPercentage = (amount: number, total: number): number => Math.ceil((amount / total) * 100);

export const isAbortError = (error: any): boolean => {
  if ('message' in error) {
    return error.message === USER_ABORTED_UPLOAD;
  }
  return false;
};

const getFormattedFileData = (file: FileData['file']): FileData['apiUploadData'] => {
  const ext = extname(file.name);
  const name = basename(file.name, ext);
  if (ext[0] !== '.') throw Error('Missing file extension');
  return {
    size: file.size,
    ext: ext.split('.')[1],
    importedName: name,
    mime: file.type,
    type: getClipTypeFromExt(getExt(file.name), file.name),
    recordedAt: new Date(file.lastModified),
    tags: [],
  };
};

export const createUpload = ({ file, batchId }: { file: Upload['file']; batchId: string }): Upload => ({
  id: uniqueId('upload'),
  batchId,
  file,
  apiUploadData: getFormattedFileData(file),
  createdAt: Date.now(),
  status: UploadStatus.queued,
  isNonMedia: getClipTypeFromExt(getExt(file.name), file.name) === ClipType.nonMedia,
  clipId: '',
  s3Info: {},
});

export const abortUpload = (upload: LargeUpload | SmallUpload) => {
  if (isLarge(upload)) {
    abortMultipartUpload(upload.xhr);
  } else if (upload.xhr) {
    abortUploadRequest(upload.xhr);
  }
};

export const abortMultipartUpload = (xhrRequests: LargeUpload['xhr']) => {
  if (xhrRequests) {
    Object.keys(xhrRequests).forEach((partNumber) => {
      const xhr = xhrRequests?.[parseInt(partNumber)];
      if (xhr) {
        abortUploadRequest(xhr);
      }
    });
  }
};

export const getChunksCountForLargeFile = (upload: Upload) =>
  upload.apiUploadData.size ? Math.ceil(upload.apiUploadData.size / CHUNK_SIZE) : 0;

export const getLargeFileChunks = (upload: Upload) => {
  const fileChunks = [];

  if (upload.apiUploadData.size) {
    for (let i = 0; i < getChunksCountForLargeFile(upload); i++) {
      const startPoint = i * CHUNK_SIZE;
      fileChunks.push(upload.file.slice(startPoint, startPoint + CHUNK_SIZE));
    }
  }

  return fileChunks;
};
