import { setUploadsPausedAction, Upload } from '@air/redux-uploader';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { abortUpload } from '../utilities';

export const usePauseUploads = () => {
  const dispatch = useDispatch();

  const pauseUploads = useCallback(
    async (uploads: Upload[]) => {
      dispatch(setUploadsPausedAction({ uploadIds: uploads.map(({ id }) => id) }));
      uploads.forEach((upload) => abortUpload(upload));
    },
    [dispatch],
  );

  return {
    pauseUploads,
  };
};
