import { InputFile, MutableInputFile } from '@air/component-upload-dropzone';

/**
 * The following characters are restricted in files or folders on one or more operating systems supported by Air's desktop app.
 * In order to prevent issues with file synchronization, we prevent these characters from being used in the names of resources that can
 * map to files or folders e.g. boards, asset versions, libraries
 */
const RESTRICTED_PATH_CHARS_RE = /[:|\n\r\t]/;

/**
 * Validates whether a string contains restricted Air path characters
 *
 * (In order to prevent issues with file synchronization, we prevent special characters from being used in the names of resources that can
 * map to files or folders e.g. boards, asset versions, libraries)
 */
export const containsRestrictedPathChars = (s: string) => RESTRICTED_PATH_CHARS_RE.test(s);

/**
 * Sanitizes a string by replacing restricted Air path characters with safe alternatives.
 * @param input Unsanitized string (commonly a resource (e.g board, asset version, library...) name)
 */
export const sanitizeRestrictedPathChars = (input: string): string =>
  input.replaceAll(':', '-').replaceAll('|', '-').replaceAll('\r', ' ').replaceAll('\t', ' ').replaceAll('\n', ' ');

export const sanitizeRestrictedPathCharsInFile = (file: InputFile): InputFile => {
  const mutableCopiedFile: MutableInputFile = new File([file], sanitizeRestrictedPathChars(file.name), {
    type: file.type,
  });
  mutableCopiedFile.path = file.path ? sanitizeRestrictedPathChars(file.path) : undefined;

  return mutableCopiedFile;
};
