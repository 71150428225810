import { ChevronLeft, ChevronRight } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { format as formatDate } from 'date-fns';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

export type DateInputHeaderProps = ReactDatePickerCustomHeaderProps;

export const DateInputHeader = ({
  date,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  increaseMonth,
  decreaseMonth,
}: DateInputHeaderProps) => {
  return (
    <div className="mb-3 flex items-center justify-between">
      <IconButton
        appearance="ghost"
        color="grey"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        icon={ChevronLeft}
        size="small"
        label="Show previous month"
      />
      <div className="text-12 font-medium text-grey-11">{formatDate(date, 'MMMM yyyy')}</div>
      <IconButton
        appearance="ghost"
        color="grey"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        icon={ChevronRight}
        size="small"
        label="Show next month"
      />
    </div>
  );
};
