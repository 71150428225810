import { callWithRetry, getExponentialBackoff } from '@air/call-with-retry';
import { NetworkStatusInfo } from '@air/classes-network-status-info';
import { uploadByIdSelector, UploadStatus, useUploaderStore } from '@air/redux-uploader';
import { useCallback } from 'react';

import { UploadFileChunkParams, useUploadLargeFileChunk } from './useUploadLargeFileChunk';

const MAX_RETRIES = 5;

export const useUploadLargeFileChunkWithRetries = () => {
  const store = useUploaderStore();
  const { uploadLargeFileChunk } = useUploadLargeFileChunk();

  const uploadLargeFileChunkWithRetries = useCallback(
    (params: UploadFileChunkParams) =>
      callWithRetry({
        action: () => uploadLargeFileChunk(params),
        getRetryDelay: (retries) =>
          getExponentialBackoff({
            attempt: retries,
            factor: 2,
            initialDelay: 200,
            maxDelay: 30_000,
          }),
        shouldRetry: async (retries, error) => {
          const updatedUpload = uploadByIdSelector(store.getState(), params.upload.id);

          console.group('shouldRetry');
          console.info('updatedUpload', updatedUpload);
          console.info('partNumber', params.partNumber);
          console.info('retries', retries);
          console.info('error', error);
          console.groupEnd();

          /**
           * If the upload doesn't exist (the user might've cancled it),
           * don't bother retrying
           */
          if (!updatedUpload) {
            return false;
          }

          /** if they paused the upload, don't retry*/
          if (updatedUpload?.status === UploadStatus.paused) {
            return false;
          }

          /** if they've hit the max number of retries, prevent a retry */
          if (retries >= MAX_RETRIES) {
            return false;
          }

          const isOnline = await NetworkStatusInfo.checkIfOnline();

          console.info('isOnline', isOnline);

          /** If they are online, let's retry again */
          return isOnline;
        },
        onError: (error) => {
          console.info('error caught during upload', error);

          const updatedUpload = uploadByIdSelector(store.getState(), params.upload.id);

          /** if they paused the upload, don't report error */
          if (updatedUpload?.status === UploadStatus.paused) {
            return;
          }

          params.onPartError?.({
            upload: params.upload,
            error,
            partNumber: params.partNumber,
          });
        },
      }),
    [store, uploadLargeFileChunk],
  );

  return {
    uploadLargeFileChunkWithRetries,
  };
};
