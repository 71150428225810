import { tailwindMerge } from '@air/tailwind-variants';

import { ASSET_STATE_TEXT, EXT_PREVIEW_BOX } from '~/constants/testIDs';
import { ClipIconSize } from '~/types/clip-icon-size';
import { getExtStyles } from '~/utils/FileExtensionUtils';

export type FileExtensionPreviewSize = 'small' | 'mediumSmall' | 'medium' | 'large';
export type FileExtensionPreviewColor = 'gray' | 'color' | 'default';

export interface FileExtensionPreviewProps {
  /** clip extension used to differentiate file icons and colors */
  ext: string;
  /** text we want to show below the icon */
  text?: string;
  /** color of icon & text with three types */
  color?: FileExtensionPreviewColor;
  /** size of icon & text with three types */
  size?: FileExtensionPreviewSize;

  className?: string;
}

/**
 * This component is used to to differentiate files by icon and color based on the file's extension.
 * Styling is different for icons on the modal, in file table view, and file gallery view.
 * Icons are grey when processing a new upload.
 */
const FileExtensionPreview = ({ ext, color = 'color', size = 'small', text, className }: FileExtensionPreviewProps) => {
  const sizeMap: { [key in string]: { icon: ClipIconSize; font?: 8 | 10 | 12; textMarginTop?: number } } = {
    small: {
      icon: ClipIconSize.small,
      font: 8,
      textMarginTop: 2,
    },
    mediumSmall: {
      icon: ClipIconSize.mediumSmall,
      font: 10,
      textMarginTop: 2,
    },
    medium: {
      icon: ClipIconSize.medium,
      font: 12,
      textMarginTop: 10,
    },
  };

  const { iconName, iconComponent: IconComponent, iconColor, modalIconColor } = getExtStyles(ext);

  const colorMap: { [key in string]: string } = {
    gray: 'var(--colors-grey9)',
    color: iconColor,
    default: modalIconColor,
  };

  const extIconColor = 'currentColor';

  return (
    <div
      className={tailwindMerge('flex flex-col items-center', className)}
      data-testid={EXT_PREVIEW_BOX}
      style={{ color: colorMap[color] }}
    >
      <IconComponent data-testid={iconName} style={{ width: sizeMap[size].icon, color: extIconColor }} />
      {!!text && (
        <div
          className="text-center text-14 font-bold uppercase"
          data-testid={ASSET_STATE_TEXT}
          style={{
            marginTop: sizeMap[size].textMarginTop,
            // using fontSize because size 8 font (uppercased is a visual 10) is not in our TextVariant system
            fontSize: sizeMap[size].font,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default FileExtensionPreview;
