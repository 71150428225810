import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithoutRef } from 'react';

export type SelectChipOptionContainerProps = ComponentPropsWithoutRef<'div'>;

export const SelectChipOptionContainer = ({ className, ...restOfProps }: SelectChipOptionContainerProps) => (
  <div
    className={tailwindMerge(
      'flex w-full cursor-pointer rounded bg-transparent p-1 transition-colors hover:bg-grey-4',
      className,
    )}
    {...restOfProps}
  />
);
