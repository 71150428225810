import { Plus } from '@air/next-icons';
import classNames from 'classnames';
import { memo } from 'react';

import { SELECT_CREATE_OPTION } from '~/components/Select/shared/testIDs';

interface SelectCreateOptionProps {
  onClick: () => void;
  value: string;
  hideSpacer: boolean;
  createVerb?: string;
}

export const SelectCreateOption = memo(
  ({ onClick, hideSpacer, value, createVerb = 'Create' }: SelectCreateOptionProps) => {
    return (
      <li
        className={classNames('w-full', hideSpacer ? 'border-t-none mt-0' : 'mt-2 border-t border-t-grey-5')}
        data-testid={SELECT_CREATE_OPTION}
        onClick={onClick}
      >
        <div className={classNames('w-full', hideSpacer ? 'mt-0' : 'mt-2')}>
          <div
            className={classNames(
              'flex w-full cursor-pointer items-center justify-start',
              hideSpacer ? 'mt-0' : 'mt-3',
            )}
          >
            <Plus className="h-4 w-4 text-grey-11" />
            <div className="ml-1.5 text-14 font-medium text-grey-11">{`${createVerb} "${value}"?`}</div>
          </div>
        </div>
      </li>
    );
  },
);

SelectCreateOption.displayName = 'SelectCreateOption';
